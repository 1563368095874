// Angular
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
// Toast
import { ToastService } from '../../../@fuse/utils/toast.service';
// Constants
import { Constants } from '../../../@fuse/utils/constants';
import { environment } from '../../../environments/environment';
// Translate
import { FuseTranslationLoaderService } from '../../../@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './login/i18n/en';
import { locale as spanish } from './login/i18n/es';
// Local Service
import { OauthService } from './oauth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public oauthService: OauthService,
    public router: Router,
    public toast: ToastService,
    public translate: TranslateService,
  ) {
    this._fuseTranslationLoaderService.loadTranslations();
    this._fuseTranslationLoaderService.loadTranslations(spanish, english);
    this.translate.use(environment.DEFAULT_LANG);
  }
  canActivate(): boolean {
    if (!this.oauthService.isAuthenticated()) {
      this.toast.showToast(
        Constants.TOAST_TYPE_ERROR,
        this.translate.instant('MESSAGES.USER_NOT_AUTHENTICATED'),
      );
      this.router.navigate(['oauth/login']);
      return false;
    }
    return true;
  }
}
