// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {Global} from '../../../@fuse/utils/global';

@Injectable({
  providedIn: 'root'
})
export class ComplexService {

  constructor(
    private http: HttpClient,
  ) { }

  public getComplexes(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.get(
      `${Global.BASE_API_URL}complex`,
      httpOptions,
    );
  }

}
