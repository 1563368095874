// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Local components
import { Global } from '@fuse/utils/global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(
    private http: HttpClient,
  ) { }

  public getListEmployee(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}employee`,
      httpOptions,
    );
  }

  public getEmployee(employeeId: any, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}employee/${employeeId}`,
      httpOptions,
    );
  }

  public addEmployee(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.post(
      `${Global.BASE_API_URL}employee`,
      data,
      httpOptions,
    );
  }

  public changeEmployee(employeeId: any, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.put(
      `${Global.BASE_API_URL}employee/${employeeId}`,
      data,
      httpOptions,
    );
  }

  public removeEmployee(employeeId: any, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.delete(
      `${Global.BASE_API_URL}employee/${employeeId}`,
      httpOptions,
    );
  }

  public getListJob(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}job/list`,
      httpOptions,
    );
  }

  public getListCountry(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.get(
      `${Global.BASE_API_URL2}country/get_all`,
      httpOptions,
    );
  }

  public getListSalary(complexId: any, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}complex/salary/${complexId}`,
      httpOptions,
    );
  }

}
