<div class="p-12" [fxHide]="data.length === 0">
  <span class="h2"><strong>{{ 'INFO.DISCOUNT'|translate }}</strong> </span>
  <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" fxHide.lt-md>
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INFO.ID'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.id|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INFO.NAME'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.name|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INFO.DESCRIPTION'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.description|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INFO.AMOUNT'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.currency.iso }} {{ row.amount|number: '1.2-2'|ReplaceText }} </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <div class="column" fxFlexFill fxHide.gt-sm>
    <mat-card class="w-100-p mt-8 mb-8" *ngFor="let discount of data">
      <div fxLayout="column" fxLayoutAlign="center space-between">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'INFO.ID'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ discount.id|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'INFO.NAME'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ discount.name|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'INFO.DESCRIPTION'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ discount.description|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'INFO.AMOUNT'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
           {{ discount.currency.iso}} {{ discount.amount|number: '1.2-2'|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
      </div>
    </mat-card>
  </div>
</div>



