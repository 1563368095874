// Angular
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Material
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// Local services
import { ToastService } from '@fuse/utils/toast.service';
import { EmployeeService } from '../../employee.service';
// Constants
import { Constants } from '@fuse/utils/constants';

@Component({
  selector: 'employee-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges{
// data
  @Input()
  complexId = '';
  @Input()
  searchString = '';
  // Data bindings
  data = [];
  dataSource = new MatTableDataSource();
  displayedColumns = [
    'name',
    'createdBy',
    'identificationNumber',
    'jobEs',
    'jobEn',
    'mainPhone',
    'email',
    'actions',
  ];

  // Pagination
  pageNumber = 1;
  itemsPerPage = 10;
  totalItems = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // Sort
  @ViewChild(MatSort) sort: MatSort;
  // Common components
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  constructor(
    private employeeService: EmployeeService,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private matDialog: MatDialog,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.getEmployees();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  changePage(event: any): void {
    this.pageNumber = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.getEmployees();
  }

  getEmployees(): void {
    this.loader.start();
    this.employeeService.getListEmployee({
      complexId: this.complexId,
      pageNumber: this.pageNumber,
      itemsPerPage: this.itemsPerPage,
      searchString: this.searchString,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.data = result.data.data;
          this.dataSource = new MatTableDataSource(result.data.data);
          this.dataSource.sort = this.sort;
          this.totalItems = +result.data.totalItems;
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  deleteEmployee(id: any): void {
    this.confirmDialogRef = this.matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage =
      this.translate.instant('MESSAGES.DELETE_EMPLOYEE_ALERT');
    this.confirmDialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.loader.start();
        this.employeeService.removeEmployee(
          id, {
          employeeId: id,
        }).subscribe(
          result => {
            if (result.success === true) {
              this.toast.showToast(
                Constants.TOAST_TYPE_SUCCESS,
                this.translate.instant('MESSAGES.DELETE_EMPLOYEE_SUCCESS'),
              );
              this.loader.stop();
              this.getEmployees();
            } else {
              this.toast.showToast(
                Constants.TOAST_TYPE_ERROR,
                this.translate.instant('MESSAGES.' + result.message),
              );
              this.loader.stop();
            }
          }, () => {
            this.router.navigate(['/pages/error/']);
            this.loader.stop();
          },
        );
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.complexId && (changes.complexId.currentValue !== ''
      && changes.complexId.currentValue !== changes.complexId.previousValue)) {
      this.getEmployees();
    }
    if (changes.searchString
      && changes.searchString.previousValue !== undefined && this.complexId !== ''
      && (changes.searchString.currentValue !== changes.searchString.previousValue)) {
      this.getEmployees();
    }
  }

}
