export const locale = {
  lang: 'en',
  data: {
    'NAV': {
      'APPLICATIONS': 'Applications',
      'DASHBOARD': {
        'TITLE': 'Home',
      },
      'CATALOGUE': {
        'TITLE': 'Configurations',
        'JOB': {
          'TITLE': 'Job',
        },
        'SALARY_DETAIL': {
          'TITLE': 'Salary detail',
        },
        'ACCOUNTING_ACCOUNT': {
          'TITLE': 'Accounting account',
        },
      },
      'EMPLOYEE': {
        'TITLE': 'Employees',
      },
      'DISTRIBUTION': {
        'TITLE': 'Distribution',
      },
      'PETTY_CASH': {
        'TITLE': 'Petty cash',
      },
      'ACCOUNTS_RECEIVABLE': {
        'TITLE': 'Receivable accounts',
      },
      'CONCILIATION': {
        'TITLE': 'Conciliation',
      },
      'INSURANCE': {
        'TITLE': 'Insurance',
      },
      'EXECUTION': {
        'TITLE': 'Execution',
      },
      'BUDGET': {
        'TITLE': 'Budget',
      },
      'INCOME': {
        'TITLE': 'Income',
      },
      'EXPENSE': {
        'TITLE': 'Expense',
      },
      'DEPOSIT': {
        'TITLE': 'Deposit voucher',
      },
      'BANK_ACCOUNT': {
        'TITLE': 'Bank accounts',
      },
      'USER': {
        'TITLE': 'Users',
      },
      'REPORT': {
        'TITLE': 'Reports',
      },
    }
  }
};
