// Angular
import { NgModule } from '@angular/core';
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
// Fuse
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
// Translate
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    FuseConfirmDialogComponent
  ],
  imports: [
    // Material
    MatDialogModule,
    MatButtonModule,
    // Translate
    TranslateModule
  ],
  entryComponents: [
    FuseConfirmDialogComponent
  ],
})
export class FuseConfirmDialogModule {
}
