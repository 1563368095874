// Angular
import {
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Toast
import { ToastService } from '@fuse/utils/toast.service';
// Constants
import { Constants } from '@fuse/utils/constants';
// Local service
import { ConciliationService } from '../../conciliation.service';

@Component({
  selector: 'conciliation-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class DetailComponent implements OnInit {
  // Data binding
  accountId = '';
  complexId = '';
  conciliatedId = '';
  data = [];
  data2 = [];
  fileForm: FormGroup;
  loadForm: FormGroup;
  x = 1;
  file = {
    file: File = null,
    extension: '',
    type: '',
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private conciliateServices: ConciliationService,
    private formBuilder: FormBuilder,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.complexId = this.activatedRoute.snapshot.paramMap.get('complexId');
    this.conciliatedId = this.activatedRoute.snapshot.paramMap.get('conciliatedId');
    this.accountId = this.activatedRoute.snapshot.paramMap.get('accountId');
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.fileForm = this.formBuilder.group({
      // file: ['', Validators.required]
    });
    this.loadForm = this.formBuilder.group({
      document: ['A', [Validators.required, Validators.pattern('^[0-9]\\d*?$')]],
      credit: ['A', [Validators.required, Validators.pattern('^[0-9]\\d*?$')]],
      debit: ['A', [Validators.required, Validators.pattern('^[0-9]\\d*?$')]],
      date: ['A', [Validators.required, Validators.pattern('^[0-9]\\d*?$')]],
    });
    this.getConciliationTransactions();
  }

  onSelect(event: any): void {
    this.file.file = event.addedFiles[0];
    const type = this.file.type.split('/')[1];
    this.file.extension = type;
    if (type === Constants.TYPE_FILE_CSV) {
      this.file.type = Constants.TYPE_FILE_CSV;
    } else {
      this.file.type = Constants.TYPE_FILE_CSV;
    }
  }

  onRemove(event: any): void {
    this.file.file = null;
    this.file.extension = '';
    this.file.type = '';
  }

  fileColumns(): void{
    this.loader.start();
    this.conciliateServices.addFileColumns({
      bankAccountId: this.accountId,
      file: this.file.file,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.data = result.data;
          this.toast.showToast(
            Constants.TOAST_TYPE_SUCCESS,
            this.translate.instant('MESSAGES.FILE_COLUMNS_SUCCESS'),
          );
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, error => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      },
    );
  }

  loadFile(): void{
    this.loader.start();
    this.conciliateServices.addLoadFile({
      conciliationId: this.conciliatedId,
      file: this.file.file,
      documentColumnNumber: this.loadForm.controls.document.value,
      debitColumnNumber: this.loadForm.controls.debit.value,
      creditColumnNumber: this.loadForm.controls.credit.value,
      dateColumnNumber: this.loadForm.controls.date.value,
      complexId: this.complexId,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.toast.showToast(
            Constants.TOAST_TYPE_SUCCESS,
            this.translate.instant('MESSAGES.LOAD_FILE_SUCCESS'),
          );
          this.getConciliationTransactions();
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, error => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      },
    );
  }

  getConciliationTransactions(): void {
    this.loader.start();
    this.conciliateServices.getConciliationTransaction({
      conciliationId: this.conciliatedId,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.data2 = result.data;
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

}
