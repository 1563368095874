// Angular
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseConfirmDialogComponent } from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Material
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// Local services
import { ToastService } from '@fuse/utils/toast.service';
// Pipes
import { DatePipe } from '@angular/common';
// Constants
import {Constants, Months, Years} from '../../../../../@fuse/utils/constants';
// Local services
import { ConciliationService } from '../../conciliation.service';
import { IncomeService } from '../../../income/income.service';
import { ReceivableAccountsService } from '../../../receivable-accounts/receivable-accounts.service';
// Modals
import { DetailTransactionComponent } from '../../../income/main/detail/detail-transaction/detail-transaction.component';
import { DetailsPayComponent} from '../../../receivable-accounts/main/detail/details-pay/details-pay.component';

@Component({
  selector: 'conciliation-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges {
  // Data
  @Input()
  complexId = '';
  // Date
  currentDate = new Date();
  year = this.currentDate.getFullYear();
  month = this.currentDate.getMonth();
  datePipe = new DatePipe('en-US');
  // Reactive form
  filtersForm: FormGroup;
  // Filters
  monthCatalog = [];
  yearCatalog = [];
  currentMonth = (new Date().getMonth() + 1).toString();
  currentYear = new Date().getFullYear().toString();
  // Data bindings
  banks = [];
  data = [];
  accountId: '';
  conciliationId = '';
  searchString = '';
  accountBalance: any = {
    amount: '',
  };
  verifyConciliate: any = {
    create: '',
    id: '',
    status: '',
  };
  dataSource = new MatTableDataSource();
  displayedColumns = [
    'transactionId',
    'documentName',
    'nameEs',
    'createdBy',
    'date',
    'description',
    'status',
    'debit',
    'credit',
    'actions',
  ];

  // Pagination
  pageNumber = 1;
  itemsPerPage = 10;
  totalItems = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // Sort
  @ViewChild(MatSort) sort: MatSort;
  // Modal details
  payDialogRef: MatDialogRef<DetailsPayComponent>;
  payDialogRef1: MatDialogRef<DetailTransactionComponent>;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  constructor(
    private conciliateServices: ConciliationService,
    private formBuilder: FormBuilder,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private incomesServices: IncomeService,
    private loader: NgxUiLoaderService,
    private matDialog: MatDialog,
    private receivableServices: ReceivableAccountsService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.filtersForm = this.formBuilder.group({
      status: ['0', [Validators.required]],
      type: ['0', [Validators.required]],
      month: [this.currentMonth],
      year: [this.currentYear],
      bankAccount: ['', Validators.required],
    });
    this.loadFilters();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  changePage(event: any): void {
    this.pageNumber = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.getListBanks();
  }

  loadFilters(): void {
    this.monthCatalog = [];
    this.yearCatalog = [];
    const currentYear = +this.filtersForm.controls.year.value;
    const currentMonth = +this.filtersForm.controls.month.value;
    const cYear = new Date().getFullYear();
    Years.forEach(year => {
      if (+year.value >= cYear) {
        this.yearCatalog.push(year);
      }
      this.getListBanks();
    });
    Months.forEach(month => {
      if (new Date().getFullYear() !== currentYear) {
        this.monthCatalog.push(month);
      }
      if (new Date().getFullYear() === currentYear) {
          this.monthCatalog.push(month);
      }
      this.getListBanks();
    });
  }

  getListBanks(): void{
    this.loader.start();
    this.conciliateServices.getListBankAccounts({
      complexId: this.complexId,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.banks = result.data;
          if (this.banks.length > 0) {
            this.filtersForm.controls.bankAccount.setValue(
              this.banks[0],
            );
            this.getConciliation(this.banks[0]);
          }
          this.loader.stop();
        }
      }, error => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  getVerifyConciliate(bankId: any): void{
    this.loader.start();
    this.conciliateServices.getVerifyConciliation({
      complexId: this.complexId,
      bankAccountId: bankId,
      month: this.filtersForm.controls.month.value,
      year: this.filtersForm.controls.year.value,
    }).subscribe(
      result => {
        if (result.success === true && result.data) {
          this.verifyConciliate.create = result.data.create;
          this.verifyConciliate.id = result.data.id;
          this.verifyConciliate.status = result.data.status;
          this.accountId = bankId;
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  getConciliation(bank: any): void {
    this.loader.start();
    this.conciliateServices.getTransactions({
      bankAccountId: bank.id,
      month: this.filtersForm.controls.month.value,
      year: this.filtersForm.controls.year.value,
      type: this.filtersForm.controls.type.value,
      status: this.filtersForm.controls.status.value,
      pageNumber: this.pageNumber,
      itemsPerPage: this.itemsPerPage,
      searchString: this.searchString,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.data = result.data.data;
          this.accountBalance.amount = result.data.accountBalance;
          this.dataSource = new MatTableDataSource(result.data.data);
          this.dataSource.sort = this.sort;
          this.totalItems = +result.data.totalItems;
          this.getVerifyConciliate(bank.id);
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  detailsTransaction(infoTotal: any, transactionId): void {
    const detailsInfo = {
      infoTotal,
      transactionId,
    };
    this.payDialogRef = this.matDialog.open(DetailsPayComponent, {
      panelClass: 'dialog-pay1',
      disableClose: false
    });
    this.payDialogRef.componentInstance.data = detailsInfo;
    this.payDialogRef.afterClosed().subscribe(data => {
      if (data.confirm) {
        this.loader.start();
        this.receivableServices.getTransactionDetail({
          lang: this.translate.currentLang,
        }).subscribe(
          result => {
            this.loader.stop();
          },
          () => {
            this.router.navigate(['/pages/error/']);
            this.loader.stop();
          }
        );
      }
    });
  }

  detailsTransactions(infoTotal: any, transactionId): void {
    const detailsInfo = {
      infoTotal,
      transactionId,
    };
    this.payDialogRef1 = this.matDialog.open(DetailTransactionComponent, {
      panelClass: 'dialog-pay',
      disableClose: false
    });
    this.payDialogRef1.componentInstance.data = detailsInfo;
    this.payDialogRef1.afterClosed().subscribe(data => {
      if (data.confirm) {
        this.loader.start();
        this.incomesServices.getTransaction(
          transactionId, {
            transactionId: transactionId,
          }).subscribe(
          result => {
            this.loader.stop();
          },
          () => {
            this.router.navigate(['/pages/error/']);
            this.loader.stop();
          }
        );
      }
    });
  }

  conciliateOperation(): void {
    this.confirmDialogRef = this.matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage =
      this.translate.instant('MESSAGES.CREATE_CONCILIATE_ALERT');
    this.confirmDialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.loader.start();
        this.conciliateServices.addCreateConciliate({
            complexId: this.complexId,
            month: this.filtersForm.controls.month.value,
            year: this.filtersForm.controls.year.value,
            bankAccountId: this.filtersForm.controls.bankAccount.value,
          }).subscribe(
          result => {
            if (result.success === true) {
              this.conciliationId = result.data.conciliatedId;
              this.toast.showToast(
                Constants.TOAST_TYPE_SUCCESS,
                this.translate.instant('MESSAGES.CONCILIATE_SUCCESS'),
              );
              this.router.navigate([
                '/conciliation/main/detail',
                this.complexId,
                result.data.conciliationId,
                this.accountId,
              ]);
              this.loader.stop();
            } else {
              this.toast.showToast(
                Constants.TOAST_TYPE_ERROR,
                this.translate.instant('MESSAGES.' + result.message),
              );
              this.loader.stop();
            }
          }, () => {
            this.router.navigate(['/pages/error/']);
            this.loader.stop();
          },
        );
      }
    });
  }

  changeCurrentBank(): void {
    this.getListBanks();
  }
  changeCurrentType(): void{
    this.getListBanks();
  }

  changeCurrentStatus(): void{
    this.getListBanks();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.complexId && (changes.complexId.currentValue !== ''
      && changes.complexId.currentValue !== changes.complexId.previousValue)) {
      this.getListBanks();
    }
  }

}
