export const locale = {
  lang: 'en',
  data: {
    'ADD': {
      'NEW_EMPLOYEE': 'Add new employee',
      'UPDATE_EMPLOYEE': 'Update employee',
      'NAME': '*Names',
      'SURNAME': '*Surnames',
      'DPI': '*DPI',
      'JOB': 'Job',
      'PHONE': '*Main phone',
      'EMAIL': '*Email',
      'SALARY': '*Base salary',
      'BOND': 'Bonds',
      'DISCOUNT': 'Discounts',
      'TYPE_BOND': 'Bond',
      'TAX': 'Tax',
      'COUNTRY_CODE': '*Code',
      'SAVE': 'Save',
      'CANCEL': 'Cancel',
      'UPDATE': 'Update',
    },
    'VALIDATIONS': {
      'NAME_REQUIRED': 'Name is required.',
      'SURNAME_REQUIRED': 'Surname is required.',
      'DPI_REQUIRED': 'DPI is required',
      'PHONE_REQUIRED': 'Phone is required.',
      'EMAIL_REQUIRED': 'Email is required.',
      'SALARY_REQUIRED': 'Salary is required',
      'JOB_REQUIRED': 'Job is requerired',
      'TAX_REQUIRED': 'Tax is required',
      'BAD_EMAIL_FORMAT': 'Bad email format.',
      'COUNTRY_CODE_REQUIRED': 'Country code is required',
      'NUMBER_REQUIRED': 'Only numbers are accepted',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Bad Parameters',
      'EMPLOYEE_ALREADY_EXIST': 'Employee already exist.',
      'EMPLOYEE_SUCCESS': 'Employee added successfully.',
      'EMPLOYEE_SUCCESS_UPDATE': 'Employee update successfully.',
    },
  }
};
