<ngx-ui-loader></ngx-ui-loader>
<div class="page-layout simple tabbed">
<header-detail-category></header-detail-category>
  <div>
    <div class="page-wrapper">
      <div fxLayout="row wrap" class="p-12" fxLayoutAlign="strech strech">
        <mat-card class="w-100-p">
          <div fxLayout="row wrap" class="p-12" fxLayoutAlign="space-between center">
            <div fxFlex.gt-sm="50%" fxFlex.lt-md="100%"
                 fxLayoutAlign.gt-sm="start center"
                 fxLayoutAlign.lt-md="start start">
              <div fxLayout="column">
                <span class="h1"><strong>{{ 'INFO.INFORMATION'|translate }}</strong></span>
                <span class="mt-12"><strong>{{ 'INFO.NAME'|translate }}</strong> {{ info.name }}</span>
                <span class="mt-12"><strong>{{ 'INFO.DESCRIPTION'|translate }}</strong> {{ info.description }}</span>
              </div>
            </div>
            <div fxFlex.gt-sm="50%" fxFlex.lt-md="100%"
                 fxLayoutAlign.gt-sm="end end"
                 fxLayoutAlign.lt-md="start start">
              <div fxLayout="column">
                <div fxLayout="row">
                  <button
                    mat-raised-button class="mt-12"
                    color="accent"
                    matTooltip="{{ 'INFO.EDIT'|translate }}"
                    [routerLink]="['/catalogue/accounting-account/main/edit-category', complexId, categoryId]">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button
                    mat-raised-button color="warn" class="ml-12 mt-12"
                    matTooltip="{{ 'INFO.REMOVE'|translate }}"
                    (click)="deleteCategory(info.categoryId);">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <table-account></table-account>
</div>
