export const locale = {
  lang: 'en',
  data: {
    'ADD_ACCOUNT': {
      'CREATE_ACCOUNT': 'Create account',
      'UPDATE_ACCOUNT': 'Update account',
      'NAME': '*Name',
      'DESCRIPTION': '*Description',
      'SAVE': 'Save',
      'UPDATE': 'Update',
      'CANCEL': 'Cancel',
    },
    'VALIDATIONS': {
      'NAME_REQUIRED': 'Name is required',
      'DESCRIPTION_REQUIRED': 'Description is required',
    },
    'MESSAGES': {
      'ACCOUNT_SUCCESS': 'Account created successufully',
      'ACCOUNT_UPDATE_SUCCESS': 'Account update successufully',
    },
  }
};
