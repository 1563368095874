<div id="error" fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
  <div class="content" fxLayout="column" fxLayoutAlign="center center">
    <div class="logo">
      <img src="assets/images/logos/beco.png" alt="assets/images/logos/beco.png">
    </div>
    <div class="message">
      <br>
      <span class="h1 white-text"><strong>{{ 'PAGES.TITLE' | translate }}</strong></span>
    </div>
    <div class="sub-message">
      <span class="h1">{{ 'PAGES.MESSAGE' | translate }}</span>
    </div>
    <a class="h2" [routerLink]="'/pages/oauth/login'">{{ 'PAGES.BACK_LOGIN'|translate }}</a>
  </div>
</div>
