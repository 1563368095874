// Angular
import { NgModule } from '@angular/core';
import { RouterModule} from '@angular/router';
// Fuse
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { FuseCountdownModule, FuseWidgetModule } from '../../../../@fuse/components';
// Loader
import { NgxUiLoaderModule } from 'ngx-ui-loader';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// Guard
import { AuthGuardService as AuthGuard } from '../../oauth/auth-guard.service';
// Common component
import { CommonComponentModule } from '../../common-component/common-component.module';
// Local component
import { AddAccountComponent } from './main/account/add-account/add-account.component';
import { AddCategoryComponent } from './main/category/add-category/add-category.component';
import { DetailComponent } from './main/detail/detail.component';
import { HeaderAddCategoryComponent } from './main/category/add-category/header-add-category/header-add-category.component';
import { HeaderComponent } from './main/header/header.component';
import { HeaderDetailCategoryComponent } from './main/detail/header-detail-category/header-detail-category.component';
import { MainComponent } from './main/main.component';
import { TableAccountComponent } from './main/account/table-account/table-account.component';
import { TableComponent } from './main/table/table.component';
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes = [
  {
    path: 'main',
    data: {breadcrumb: 'Admin. Cuentas contables'},
    children: [
      {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Cuentas contables'},
      },
      {
        path: 'add-category/:complexId',
        component: AddCategoryComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Nueva categoría'},
      },
      {
        path: 'edit-category/:complexId/:categoryId',
        component: AddCategoryComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Actualizar categoría'},
      },
      {
        path: 'detail-category/:complexId/:categoryId',
        component: DetailComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Detalles'},
      },
    ]
  },
];

@NgModule({
  declarations: [
    AddAccountComponent,
    AddCategoryComponent,
    DetailComponent,
    HeaderAddCategoryComponent,
    HeaderComponent,
    HeaderDetailCategoryComponent,
    MainComponent,
    TableAccountComponent,
    TableComponent,
  ],
    imports: [
        RouterModule.forChild(routes),
        // Translate
        TranslateModule,
        // Common component
        CommonComponentModule,
        // Fuse
        FuseCountdownModule,
        FuseSharedModule,
        FuseWidgetModule,
        // Loader
        NgxUiLoaderModule,
        // Material
        MatButtonModule,
        MatCardModule,
        MatChipsModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
    ],
  exports: [
    MainComponent,
  ],
})
export class AccountingAccountModule { }
