export const locale = {
  lang: 'en',
  data: {
    'ADD': {
      'APARTMENT_AMENITIES': 'Property environments',
      'FEE_NEW': 'Payment total: ',
      'PRICE': 'Price for mt2: ',
      'SAVE': 'Save',
      'CANCEL': 'Cancel',
      'TITLE': 'Space distribution configuration',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Bad Parameters',
      'CONFIGURATION_SUCCESS_UPDATE': 'Configuration update successfully.',
    },
  }
};
