// Angular Modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Angular Material
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// Angular Fuse
import { FuseModule } from '@fuse/fuse.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { fuseConfig } from 'app/fuse-config';
// Local Component
import { AccountingAccountModule } from './main/catalogue/accounting-account/accounting-account.module';
import { AppComponent } from './app.component';
import { ConciliationModule } from './main/conciliation/conciliation.module';
import { DistributionModule } from './main/distribution/distribution.module';
import { EmployeeModule} from './main/employee/employee.module';
import { LayoutModule } from './layout/layout.module';
import { OauthModule } from './main/oauth/oauth.module';
import { PagesModule } from './main/pages/pages.module';
// Loader
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
// jwt
import { POSITION, SPINNER, PB_DIRECTION } from 'ngx-ui-loader';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
// Guards
import { AuthGuardService } from './main/oauth/auth-guard.service';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#FFFFFF',
  fgsPosition: POSITION.centerCenter,
  fgsSize: 50,
  fgsType: SPINNER.foldingCube,
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 5,
  pbColor: '#FFFFFF',
  logoPosition: 'top-center',
};

const appRoutes: Routes = [
  {
    path: 'oauth',
    loadChildren: () => import('./main/oauth/oauth.module').then((m) => m.OauthModule),
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'catalogue',
    loadChildren: () => import('./main/catalogue/catalogue.module').then((m) => m.CatalogueModule),
  },
  {
    path: 'employee',
    loadChildren: () => import('./main/employee/employee.module').then((m) => m.EmployeeModule),
  },
  {
    path: 'distribution',
    loadChildren: () => import('./main/distribution/distribution.module').then((m) => m.DistributionModule),
  },
  {
    path: 'petty-cash',
    loadChildren: () => import('./main/petty-cash/petty-cash.module').then((m) => m.PettyCashModule),
  },
  {
    path: 'receivable-accounts',
    loadChildren: () => import('./main/receivable-accounts/receivable-accounts.module').then((m) => m.ReceivableAccountsModule),
  },
  {
    path: 'conciliation',
    loadChildren: () => import('./main/conciliation/conciliation.module').then((m) => m.ConciliationModule),
  },
  {
    path: 'insurance',
    loadChildren: () => import('./main/insurance/insurance.module').then((m) => m.InsuranceModule),
  },
  {
    path: 'execution',
    loadChildren: () => import('./main/execution/execution.module').then((m) => m.ExecutionModule),
  },
  {
    path: 'budget',
    loadChildren: () => import('./main/budget/budget.module').then((m) => m.BudgetModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'income',
    loadChildren: () => import('./main/income/income.module').then((m) => m.IncomeModule),
  },
  {
    path: 'expense',
    loadChildren: () => import('./main/expense/expense.module').then((m) => m.ExpenseModule),
  },
  {
    path: 'deposit',
    loadChildren: () => import('./main/deposit/deposit.module').then((m) => m.DepositModule),
  },
  {
    path: 'bank-account',
    loadChildren: () => import('./main/bank-account/bank-account.module').then((m) => m.BankAccountModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./main/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'report',
    loadChildren: () => import('./main/report/report.module').then((m) => m.ReportModule),
  },
  {
    path      : '**',
    redirectTo: '/oauth/login'
  },
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        TranslateModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // Loader
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        // App modules
        AccountingAccountModule,
        ConciliationModule,
        DistributionModule,
        EmployeeModule,
        LayoutModule,
        OauthModule,
        PagesModule,
    ],
    bootstrap   : [
        AppComponent,
    ],
  providers: [
    AuthGuardService,
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
})
export class AppModule
{
}
