export const locale = {
  lang: 'en',
  data: {
    'LOGIN': {
      'EMAIL': 'Email',
      'PASSWORD': 'Password',
      'FORGOT_PASSWORD': 'Forgot password?',
      'LOGIN': 'Login',
      'ENTER_ACCOUNT': 'LOGIN',
      'TITLE': 'WELCOME TO FINANCE',
      'DESCRIPTION': 'Welcome to the Beco financial management platform, you must have administrator or finance manager permissions to enter.',
    },
    'VALIDATIONS': {
      'EMAIL_REQUIRED': 'Email is required',
      'PASSWORD_REQUIRED': 'Password is required',
      'BAD_EMAIL_FORMAT': 'Enter valid email',
    },
    'MESSAGES': {
      'BAD_CREDENTIALS': 'Your password or email are incorrect',
      'USER_DONT_CHANNEL': 'This user does not belong to a channel',
      'USER_DONT_EXIST': 'User don\'t exist',
      'USER_NOT_AUTHENTICATED': 'User not authenticated',
    },
  },
};
