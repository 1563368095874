export const locale = {
  lang: 'en',
  data: {
    'FORGOT_PASSWORD': {
      'RECOVER_PASSWORD': 'RECOVER YOUR PASSWORD',
      'EMAIL': 'Email',
      'SEND_RESET': 'Recover',
      'BACK_LOGIN': 'Go back to login',
      'TITLE': 'WELCOME TO FINANCE',
      'DESCRIPTION': 'Welcome to the Beco financial management platform, you must have administrator or finance manager permissions to enter.',
    },
    'VALIDATIONS': {
      'EMAIL_REQUIRED': 'Email is required',
      'BAD_EMAIL_FORMAT': 'Please enter a valid email address',
    },
    'MESSAGES': {
      'EMAIL_DOSNT_EXIST': 'The email does not exist.',
      'CHECK_EMAIL': 'The recover instructions was sent to email.',
    },
  }
};
