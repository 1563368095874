export const locale = {
  lang: 'en',
  data: {
    'DETAIL_TRANSACTION': {
      'ACCOUNT': 'Account',
      'TITLE': 'Transaction',
      'BANK_ACCOUNT': 'Bank account',
      'DOCUMENT_NUMBER': 'Document number',
      'DATE': 'Document date',
      'DOCUMENT_NAME': 'Document name',
      'NAME_CATEGORY': 'Category name',
      'DESCRIPTION': 'Description',
      'DOCUMENT_DESCRIPTION': 'Document description',
      'NOTES': 'Notes',
      'AMOUNT': 'Amount',
      'DECLINE_PAY': 'Decline pay',
      'CANCEL': 'Cancel',
    },
    'MESSAGES': {
      'PAY_SUCCESS': 'Decline payment successfully',
    }
  }
};
