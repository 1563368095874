<div fxFlex.gt-sm="100%" fxFlex.lt-md="100%">
  <span class="h1">{{ 'DETAIL_TRANSACTION.TITLE'|translate }} #{{ data.transactionId }}</span>
  <mat-divider></mat-divider><br>
  <span><strong>{{ 'DETAIL_TRANSACTION.DESCRIPTION'|translate }}: </strong>{{ detailTransaction.description }}</span><br><br>
  <span><strong>{{ 'DETAIL_TRANSACTION.AMOUNT'|translate }}: {{ detailTransaction.currencyIso }} {{ detailTransaction.amount|number: '1.2-2' }}</strong></span><br><br>
  <span><strong>{{ 'DETAIL_TRANSACTION.DOCUMENT_NAME'|translate }}: </strong>{{ detailTransaction.documentName }}</span><br><br>
  <span><strong>{{ 'DETAIL_TRANSACTION.DOCUMENT_NUMBER'|translate }}: </strong>{{ detailTransaction.documentNumber }}</span><br><br>
  <span><strong>{{ 'DETAIL_TRANSACTION.DATE'|translate }}: </strong>{{ detailTransaction.documentDate }}</span><br><br>
  <span><strong>{{ 'DETAIL_TRANSACTION.DOCUMENT_DESCRIPTION'|translate }}: </strong>{{ detailTransaction.documentDescription }}</span><br><br>
  <span><strong>{{ 'DETAIL_TRANSACTION.NOTES'|translate }}: </strong>{{ detailTransaction.notes }}</span><br><br>
  <div>
    <button mat-raised-button
            class="ml-4" color="accent"
            (click)='closeDetail(false);'>
      {{ 'DETAIL_TRANSACTION.CANCEL'|translate }}
    </button>
  </div>
</div>

