// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Local components
import { Global } from '@fuse/utils/global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConciliationService {

  constructor(
    private http: HttpClient,
  ) { }

  public addFileColumns(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    const formData: any = new FormData();
    formData.append('bankAccountId', data.bankAccountId);
    formData.append('file', data.file);
    return this.http.post(
      `${Global.BASE_API_URL}conciliation/file/get_columns`,
      formData,
      httpOptions,
    );
  }

  public addCreateConciliate(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.post(
      `${Global.BASE_API_URL}conciliation`,
      data,
      httpOptions,
    );
  }

  public addLoadFile(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    const formData: any = new FormData();
    formData.append('conciliationId', data.conciliationId);
    formData.append('file', data.file);
    formData.append('documentColumnNumber', data.documentColumnNumber);
    formData.append('debitColumnNumber', data.debitColumnNumber);
    formData.append('creditColumnNumber', data.creditColumnNumber);
    formData.append('dateColumnNumber', data.dateColumnNumber);
    formData.append('complexId', data.complexId);
    return this.http.post(
      `${Global.BASE_API_URL}conciliation/file/load`,
      formData,
      httpOptions,
    );
  }

  public getTransactions(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}conciliation/get_all`,
      httpOptions,
    );
  }

  public getVerifyConciliation(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}conciliation/verify`,
      httpOptions,
    );
  }

  public getConciliationTransaction(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}conciliation/transaction/get_all`,
      httpOptions,
    );
  }

  public conciliateTransaction(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.put(
      `${Global.BASE_API_URL}conciliation/transaction/conciliate`,
      data,
      httpOptions,
    );
  }

  public getListBankAccounts(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}receivable_account/bank_account`,
      httpOptions,
    );
  }

}
