// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// Fuse
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'conciliation-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class MainComponent implements OnInit {
  complexId = '';
  constructor() {}

  ngOnInit(): void {
  }

  sendComplexId(complexId: any): void {
    this.complexId = complexId;
  }

}
