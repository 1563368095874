<ngx-ui-loader></ngx-ui-loader>
<div class="page-layout simple tabbed">
  <header-add></header-add>
  <div class="page-wrapper">
    <div class="p-12" fxFlexFill fxLayout="row" fxLayoutAlign="space-between start" id="add">
      <div fxFlex.gt-sm="50%" fxFlex.lt-md="100%">
        <mat-card fxLayout="column">
          <div id="add-form">
            <form [formGroup]="addForm" class="mt-12" name="addForm" novalidate>
              <span class="mt-12 h1"><strong>{{ 'ADD.APARTMENT_AMENITIES'|translate }}</strong></span><br>
              <div class="distribution mt-12" *ngFor="let distribution of distributions">
                <span><strong>{{ 'ADD.FEE_NEW'|translate }}</strong></span>
                <span class="mt-12">
                 {{ currencyIso.iso }} {{ distribution.mtPrice * distribution.mt2 | number : '1.2-2' }}
                </span><br>
                <span><strong>{{ 'ADD.PRICE'|translate }}</strong></span><span class="mt-12">{{ distribution.mtPrice }}</span><br>
                <span class="description mt-12"><strong>{{ distribution.description }}</strong></span>
                <mat-form-field appearance="outline" class="w-100-p">
                  <mat-label>{{ distribution.name }}</mat-label>
                  <input [value]="distribution.mt2" (input)="distribution.mt2 = $event.target.value" matInput>
                </mat-form-field>
              </div>
              <button (click)='newDistribution();'
                      color="primary" class="mt-12" mat-raised-button>
                {{ 'ADD.SAVE'|translate }}
              </button>
              <button [routerLink]="'/distribution/main'" class="ml-8" color="accent" mat-raised-button>
                {{ 'ADD.CANCEL'|translate }}
              </button>
            </form>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
