export const locale = {
  lang: 'en',
  data: {
    'TABLE': {
      'EMPLOYEE_NAME': 'Name',
      'CREATED_BY': 'Created by',
      'DPI': 'DPI',
      'JOB_ES': 'Job ES',
      'JOB_EN': 'Job EN',
      'PHONE': 'Phone',
      'EMAIL': 'Email',
      'SALARY': 'Salary',
      'ACTIONS': 'Actions',
      'EMPTY': 'No employees to show',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Bad parameters',
      'DELETE_EMPLOYEE_SUCCESS': 'Employee successfully delete',
      'DELETE_EMPLOYEE_ALERT': 'Are you sure to delete employee?',
      'DELETE': 'Delete employee',
      'DETAILS': 'Details',
    },
  }
};
