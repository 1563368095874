<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="navbar-toggle-button"
              *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
      <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.lt-md>
        <app-breadcrumb></app-breadcrumb>
      </div>
    </div>
    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="avatar mr-0 mr-sm-16" src="{{ logo }}" (error)="avatarError($event)">
          <span class="username mr-12" fxHide fxShow.gt-sm>
            <span *ngIf="complex.name !== ''">{{ complex.name }} - </span>
            {{ name }}
          </span>
          <mat-icon class="s-16">keyboard_arrow_down</mat-icon>
        </div>
      </button>
      <mat-menu #userMenu="matMenu">
        <button mat-menu-item class="">
          <mat-icon>exit_to_app</mat-icon>
          <span (click)="logout();">Logout</span>
        </button>
      </mat-menu>
      <div class="toolbar-separator"></div>
      <button mat-button fxShow.gt-xs class="language-button"  [matMenuTriggerFor]="languageMenu">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
          <span class="iso text-uppercase">{{ selectedLanguage.id }}</span>
        </div>
      </button>
      <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
          <span fxLayout="row" fxLayoutAlign="start center">
            <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
            <span class="iso">{{ lang.title }}</span>
          </span>
        </button>
      </mat-menu>
      <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
      <button *ngIf="notifications === 0 && role !== 'SUPER ADMIN'"
        mat-icon-button
        class="quick-panel-toggle-button"
        (click)="toggleSidebarOpen('quickPanel')"
        aria-label="Toggle quick panel">
        <mat-icon class="secondary-text">notifications</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="notifications > 0 && role !== 'SUPER ADMIN'"
        class="quick-panel-toggle-button"
        (click)="toggleSidebarOpen('quickPanel')"
        aria-label="Toggle quick panel">
        <mat-icon class="secondary-text" matBadge='{{ notifications }}' matBadgeColor='warn'>notifications</mat-icon>
      </button>
      <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>
      <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
