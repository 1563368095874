// Angular component
import { Component, OnInit } from '@angular/core';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss'],
})
export class PageErrorComponent implements OnInit {

  constructor(
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);

    // Configure the layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      }
    };
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
  }

}
