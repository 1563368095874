export const locale = {
  lang: 'es',
  data: {
    'FORGOT_PASSWORD': {
      'RECOVER_PASSWORD': 'RECUPERA TU CONTRASEÑA',
      'EMAIL': 'Correo electrónico',
      'SEND_RESET': 'Recuperar',
      'BACK_LOGIN': 'Volver a inicio',
      'TITLE': 'BIENVENIDOS A FINANCE',
      'DESCRIPTION': 'Bienvenido a la plataforma de administracion financiera de Beco, debe tener permisos de administrador o encargado de finanzas para poder ingresar.',
    },
    'VALIDATIONS': {
      'EMAIL_REQUIRED': 'El correo electrónico es requerido',
      'BAD_EMAIL_FORMAT': 'Ingrese un correo electrónico válido',
    },
    'MESSAGES': {
      'EMAIL_DOSNT_EXIST': 'El correo electrónico no existe',
      'CHECK_EMAIL': 'Las instrucciones de recuperación fueron enviadas al correo.',
    },
  }
};
