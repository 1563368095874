<div fxLayout="row wrap" class="p-12" fxLayoutAlign="strech strech">
  <mat-card class="w-100-p">
    <form name="complexSelectForm" [formGroup]="complexSelectorForm" novalidate>
      <div fxLayout="row wrap" class="p-12" fxLayoutAlign="space-between center" fxFill>
        <div fxFlex.lt-md="100%" fxFlex.gt-sm="44%">
          <div fxLayoutAlign.lt-lg="start start" fxLayoutAlign.gt-md="start start"  fxLayout="column">
            <span *ngIf="complex.id != ''" class="h1">{{ complex.name }}</span>
            <span *ngIf="complex.name != ''" class="h4">{{ complex.country }}, {{ complex.city }}, {{ complex.address }}, {{ complex.phone }}</span>
          </div>
        </div>
        <div class="mt-12" fxFlex.lt-md="100%" fxFlex.gt-sm="44%" fxLayoutAlign="end">
          <mat-form-field appearance="outline" class="w-100-p">
            <mat-label>{{ 'COMPLEX_SELECTOR.CURRENT_COMPLEX' | translate }}</mat-label>
            <mat-select formControlName="complex" (selectionChange)="changeCurrentComplex($event)">
              <mat-option *ngFor="let complex of complexes" [value]="complex">{{ complex.name }}</mat-option>
            </mat-select>
            <mat-icon matSuffix class="secondary-text">account_balance</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card>
</div>
