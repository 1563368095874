import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'applications',
    title: '',
    translate: '',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        translate: 'NAV.DASHBOARD.TITLE',
        type: 'item',
        icon: 'home',
        url: '/dashboard/main',
      },
      {
        id: 'budget',
        title: 'Budget',
        translate: 'NAV.BUDGET.TITLE',
        type: 'item',
        icon: 'playlist_add_check',
        url: '/budget/main',
      },
      {
        id: 'income',
        title: 'Income',
        translate: 'NAV.INCOME.TITLE',
        type: 'item',
        icon: 'trending_up',
        url: '/income/main',
      },
      {
        id: 'expense',
        title: 'Expense',
        translate: 'NAV.EXPENSE.TITLE',
        type: 'item',
        icon: 'trending_down',
        url: '/expense/main',
      },
      {
        id: 'receivable_accounts',
        title: 'Receivable Accounts',
        translate: 'NAV.ACCOUNTS_RECEIVABLE.TITLE',
        type: 'item',
        icon: 'payment',
        url: '/receivable-accounts/main',
      },
      {
        id: 'petty_cash',
        title: 'Petty Cash',
        translate: 'NAV.PETTY_CASH.TITLE',
        type: 'item',
        icon: 'business_center',
        url: '/petty-cash/main',
      },
      {
        id: 'conciliation',
        title: 'Conciliation',
        translate: 'NAV.CONCILIATION.TITLE',
        type: 'item',
        icon: 'card_travel',
        url: '/conciliation/main',
      },
      {
        id: 'execution',
        title: 'Execution',
        translate: 'NAV.EXECUTION.TITLE',
        type: 'item',
        icon: 'assignment',
        url: '/execution/main',
      },
      {
        id: 'deposit',
        title: 'Deposit',
        translate: 'NAV.DEPOSIT.TITLE',
        type: 'item',
        icon: 'archive',
        url: '/deposit/main',
      },
      {
        id: 'distribution',
        title: 'Distribution',
        translate: 'NAV.DISTRIBUTION.TITLE',
        type: 'item',
        icon: 'list_alt',
        url: '/distribution/main',
      },
      {
        id: 'catalogue',
        title: 'Catalogue',
        translate: 'NAV.CATALOGUE.TITLE',
        type: 'collapsable',
        icon: 'settings',
        children: [
          {
            id: 'employee',
            title: 'Employees',
            translate: 'NAV.EMPLOYEE.TITLE',
            type: 'item',
            url: '/employee/main',
          },
          {
            id: 'salary-detail',
            title: 'Salary detail',
            translate: 'NAV.CATALOGUE.SALARY_DETAIL.TITLE',
            type: 'item',
            url: '/catalogue/salary-detail/main',
            exactMatch: true
          },
          {
            id: 'accounting-account',
            title: 'Accounting account',
            translate: 'NAV.CATALOGUE.ACCOUNTING_ACCOUNT.TITLE',
            type: 'item',
            url: '/catalogue/accounting-account/main',
            exactMatch: true
          },
          {
            id: 'bank_account',
            title: 'Bank Account',
            translate: 'NAV.BANK_ACCOUNT.TITLE',
            type: 'item',
            url: '/bank-account/main',
          },
          {
            id: 'insurance',
            title: 'Insurance',
            translate: 'NAV.INSURANCE.TITLE',
            type: 'item',
            url: '/insurance/main',
          },
          {
            id: 'report',
            title: 'Report',
            translate: 'NAV.REPORT.TITLE',
            type: 'item',
            url: '/report/main',
            exactMatch: true
          },
          {
            id: 'user',
            title: 'User',
            translate: 'NAV.USER.TITLE',
            type: 'item',
            url: '/user/main',
            exactMatch: true
          },
          {
            id: 'job',
            title: 'Job',
            translate: 'NAV.CATALOGUE.JOB.TITLE',
            type: 'item',
            url: '/catalogue/job/main',
            exactMatch: true
          },
        ],
      },
    ]
  }
];
