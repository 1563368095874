// Angular
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Constants
import { Constants } from '@fuse/utils/constants';
// Toast
import { EmployeeService } from '../../employee.service';
import { ToastService } from '@fuse/utils/toast.service';

@Component({
  selector: 'employee-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class AddComponent implements OnInit {
  // Reactive form
  addForm: FormGroup;
  // Data bindings
  jobs = [];
  employeeId = '';
  codes = [];
  currencyId = '1';
  complexId = '';
  salaryBase: any = {};
  complexCurrency: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private employeeService: EmployeeService,
    private formBuilder: FormBuilder,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.complexId = this.activatedRoute.snapshot.paramMap.get('complexId');
    this.employeeId = this.activatedRoute.snapshot.paramMap.get('employeeId');
    this.complexCurrency = localStorage.getItem('complexCurrency');
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.getCountries();
    this.getJobs();
    this.getSalaries();

    this.addForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      dpi: ['', [Validators.required, Validators.pattern('^[0-9]\\d*?$')]],
      phone_main: ['', [Validators.required, Validators.pattern('^[0-9]\\d*?$')]],
      email: ['', [Validators.email]],
      tax: ['', [Validators.pattern('^[0-9]\\d*?$')]],
      countryCode: ['', Validators.required],
      job: ['', Validators.required],
      baseSalary: ['', [Validators.pattern('^[0-9]\\d*(\\.\\d+)?$'), Validators.required]],
    });

    if (this.employeeId !== '' && this.employeeId !== null && this.employeeId !== undefined) {
      if (this.complexId !== '' && this.complexId !== null && this.complexId !== undefined) {
        this.getEmployeeInfo();
      }
    }
  }

  newEmployee(): void {
    this.loader.start();
    this.salaryBase.baseSalary = this.addForm.controls.baseSalary.value;
    this.employeeService.addEmployee({
      name: this.addForm.controls.name.value,
      surname: this.addForm.controls.surname.value,
      identificationNumber: this.addForm.controls.dpi.value,
      mainPhone: this.addForm.controls.phone_main.value,
      email: this.addForm.controls.email.value,
      tax: this.addForm.controls.tax.value,
      countryId: this.addForm.controls.countryCode.value,
      jobId: this.addForm.controls.job.value,
      complexId: this.complexId,
      currencyId: this.currencyId,
      salary: this.salaryBase,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.toast.showToast(
            Constants.TOAST_TYPE_SUCCESS,
            this.translate.instant('MESSAGES.EMPLOYEE_SUCCESS'),
          );
          this.router.navigate(['/employee/main']);
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, error => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      },
    );
  }

  updateEmployee(): void {
    this.loader.start();
    this.salaryBase.baseSalary = this.addForm.controls.baseSalary.value;
    this.employeeService.changeEmployee(
      this.employeeId, {
        name: this.addForm.controls.name.value,
        surname: this.addForm.controls.surname.value,
        identificationNumber: this.addForm.controls.dpi.value,
        mainPhone: this.addForm.controls.phone_main.value,
        email: this.addForm.controls.email.value,
        tax: this.addForm.controls.tax.value,
        countryId: this.addForm.controls.countryCode.value,
        jobId: this.addForm.controls.job.value,
        complexId: this.complexId,
        currencyId: this.currencyId,
        salary: this.salaryBase,
        id: this.employeeId,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.toast.showToast(
            Constants.TOAST_TYPE_SUCCESS,
            this.translate.instant('MESSAGES.EMPLOYEE_SUCCESS_UPDATE'),
          );
          this.router.navigate(['/employee/main/detail', this.complexId, this.employeeId]);
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, error => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      },
    );
  }

  getEmployeeInfo(): void {
    this.loader.start();
    this.employeeService.getEmployee(
      this.employeeId, {
        lang: this.translate.currentLang,
    }).subscribe(
      result => {
        if (result.success === true && result.data) {
          this.salaryBase = result.data.salary;
          this.addForm.controls.name.setValue(result.data.name);
          this.addForm.controls.surname.setValue(result.data.surname);
          this.addForm.controls.dpi.setValue(result.data.identificationNumber);
          this.addForm.controls.countryCode.setValue(result.data.countryId.toString());
          this.addForm.controls.phone_main.setValue(result.data.mainPhone);
          this.addForm.controls.job.setValue(result.data.jobId.toString());
          this.addForm.controls.email.setValue(result.data.email);
          this.addForm.controls.tax.setValue(result.data.tax);
          this.addForm.controls.baseSalary.setValue(result.data.salary.baseSalary);
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      },
      () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  getJobs(): void{
    this.loader.start();
    this.employeeService.getListJob({
      lang: this.translate.currentLang,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.jobs = result.data;
          this.loader.stop();
        }
      }, error => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  getCountries(): void{
    this.loader.start();
    this.employeeService.getListCountry().subscribe(
      result => {
        if (result.success === true) {
          this.codes = result.data;
          this.loader.stop();
        }
      }, () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  getSalaries(): void{
    this.loader.start();
    this.employeeService.getListSalary(
      this.complexId, {
        lang: this.translate.currentLang,
      }).subscribe(
      result => {
        if (result.success === true && result.data) {
          this.salaryBase = result.data;
          this.loader.stop();
        }
      }, error => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

}
