// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// Fuse
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { FuseWidgetModule } from '../../../@fuse/components';
// Loader
import { NgxUiLoaderModule } from 'ngx-ui-loader';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// Local component
import { AddComponent } from './main/add/add.component';
import { DetailComponent } from './main/detail/detail.component';
import { HeaderAddComponent } from './main/add/header-add/header-add.component';
import { HeaderComponent } from './main/header/header.component';
import { HeaderDetailComponent } from './main/detail/header-detail/header-detail.component';
import { MainComponent } from './main/main.component';
import { TableBondsComponent } from './main/detail/table-bonds/table-bonds.component';
import { TableComponent } from './main/table/table.component';
import { TableDiscountsComponent } from './main/detail/table-discounts/table-discounts.component';
// Common component
import { CommonComponentModule } from '../common-component/common-component.module';
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
// Guard
import { AuthGuardService as AuthGuard } from '../oauth/auth-guard.service';

const routes = [
  {
    path: 'main',
    data: {breadcrumb: 'Admin. Empleados'},
    children: [
      {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Empleados'},
      },
      {
        path: 'add/:complexId',
        component: AddComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Nuevo empleado'},
      },
      {
        path: 'detail/:complexId/:employeeId',
        component: DetailComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Detalles'},
      },
      {
        path: 'edit/:complexId/:employeeId',
        component: AddComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Actualizar empleado'},
      },
    ]
  },
];

@NgModule({
  declarations: [
    AddComponent,
    DetailComponent,
    HeaderAddComponent,
    HeaderComponent,
    HeaderDetailComponent,
    MainComponent,
    TableBondsComponent,
    TableComponent,
    TableDiscountsComponent,
  ],

  imports: [
    RouterModule.forChild(routes),
    // Translate
    TranslateModule,
    // Loader
    NgxUiLoaderModule,
    // Fuse
    FuseSharedModule,
    FuseWidgetModule,
    // Common component
    CommonComponentModule,
    // Material
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
  ],
  exports: [
    MainComponent,
  ],
})
export class EmployeeModule { }
