export const Constants = {
  CF: 'CF',
  MEDIA_TYPE_BANNER: 'BANNER',
  MEDIA_TYPE_LOGO: 'LOGO',
  MEDIA_TYPE_WEB_BANNER: 'WEB_BANNER',
  ROLE_SUPER_ADMIN: 'SUPER ADMIN',
  TOAST_ALERT: 'OK',
  TOAST_TYPE_ERROR: 'error',
  TOAST_TYPE_SUCCESS: 'success',
  TYPE_IMAGE_JPEG: 'jpeg',
  TYPE_IMAGE_JPG: 'jpg',
  TYPE_IMAGE_PNG: 'png',
  TYPE_IMAGE: 'IMAGE',
  TYPE_FILE: 'FILE',
  TYPE_FILE_PDF: 'pdf',
  TYPE_FILE_CSV: 'csv',
  DRAFT_STATUS: 'DRAFT',
  SEND_STATUS: 'SEND',
  APPROVE_STATUS: 'APPROVE',
  REJECT_STATUS: 'REJECT',
  ACTIVE_STATUS: 'ACTIVE',
  SIGNED_STATUS: 'SIGNED',
};

export const Months = [
  {
    value: '1',
    name: 'MONTH.JAN'
  },
  {
    value: '2',
    name: 'MONTH.FEB'
  },
  {
    value: '3',
    name: 'MONTH.MAR'
  },
  {
    value: '4',
    name: 'MONTH.APR'
  },
  {
    value: '5',
    name: 'MONTH.MAY'
  },
  {
    value: '6',
    name: 'MONTH.JUN'
  },
  {
    value: '7',
    name: 'MONTH.JUL'
  },
  {
    value: '8',
    name: 'MONTH.AUG'
  },
  {
    value: '9',
    name: 'MONTH.SEP'
  },
  {
    value: '10',
    name: 'MONTH.OCT'
  },
  {
    value: '11',
    name: 'MONTH.NOV'
  },
  {
    value: '12',
    name: 'MONTH.DEC'
  },
];

export const Years = [
  {
    value: '2020',
  },
  {
    value: '2021',
  },
  {
    value: '2022',
  },
  {
    value: '2023',
  },
  {
    value: '2024',
  },
  {
    value: '2025',
  },
];

export const Quote = {
  PARAGRAPH_ONE: 'Queremos ayudarlo a administrar todos sus complejos de viviendas y oficinas, tener residentes y empleados\n' +
    '          más felices, brindándole toda la información posible para que pueda tomar las mejores decisiones; Por este\n' +
    '          motivo, creamos un APP móvil, fácil de usar, para ayudar la comunicación entre sus inquilinos, sus\n' +
    '          supervisores y gerentes, mientras se divierten y mantenemos a todos informados.',

  PARAGRAPH_TWO: 'Obtiene información actualizada y precisa, mide la satisfacción y la felicidad general de sus complejos de\n' +
    '          vivienda y oficinas. Todo esto con un sistema de tickets disponible para todos, puede crear pequeñas\n' +
    '          encuestas, hacer seguimiento de su equipo y averiguar qué necesitan sus inquilinos, al mismo tiempo que\n' +
    '          obtienen puntos y recompensas por todas las acciones específicas que realizan.',

  PARAGRAPH_THREE: ' Los administradores tienen a su disposición un portal web el cual genera reportes mensuales automáticos y\n' +
    '          permite realizar consultas especificas. Además permite generar notificaciones a los usuarios, cargar\n' +
    '          estados de cuenta y la publicación de información relevante para los inquilinos. Los administradores o\n' +
    '          equipo de administración también cuentan con un App especial para ellos, donde podrán gestionar cualquier\n' +
    '          requerimiento, anuncio, reserva o pago que los inquilinos coloquen en la plataforma.',
};
