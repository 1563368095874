export const locale = {
  lang: 'en',
  data: {
    'TABLE': {
      'TITLE': 'Category accounts',
      'NAME': 'Name',
      'DESCRIPTION': 'Description',
      'CREATED_BY': 'Creted by',
      'ACTIONS': 'Actions',
      'ADD_ACCOUNT': 'Create account',
      'EMPTY': 'There are no category accounts to show',
    },
    'MESSAGES': {
      'REMOVE': 'Account delete',
      'EDIT': 'Account edit',
      'REMOVE_ACCOUNT_SUCCESS': 'Account successfully removed',
      'REMOVE_ACCOUNT_ALERT': 'Are you sure to remove account?',
      'ACCOUNT_UPDATE_SUCCESS': 'Account successfully update',
    },
  }
};
