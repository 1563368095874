export const locale = {
  lang: 'es',
  data: {
    'TABLE': {
      'EMPLOYEE_NAME': 'Nombre',
      'CREATED_BY': 'Creado por',
      'DPI': 'DPI',
      'JOB_ES': 'Puesto ES',
      'JOB_EN': 'Puesto EN',
      'PHONE': 'Teléfono',
      'EMAIL': 'Correo electrónico',
      'SALARY': 'Salario',
      'ACTIONS': 'Acciones',
      'EMPTY': 'No hay empleados para mostrar',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Parámetros erroneos',
      'DELETE_EMPLOYEE_SUCCESS': 'Empleado borrado exitosamente',
      'DELETE_EMPLOYEE_ALERT': '¿Está seguro de borrar este empleado?',
      'DELETE': 'Borrar empleado',
      'DETAILS': 'Detalles',
    },
  }
};
