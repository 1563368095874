<div fxLayout="column" fxFlex fusePerfectScrollbar>
  <mat-list class="date" cdkFocusRegionStart>
    <div class="secondary-text mat-display-1 mb-0 p-16">
      <div fxLayout="row" fxLayoutAlign="start start">
        <span>{{ date | date: 'mediumDate' }}</span>
      </div>
    </div>
  </mat-list>
  <mat-divider cdkFocusRegionEnd></mat-divider>
  <cdk-virtual-scroll-viewport itemSize="5" fxFlex>
    <mat-list>
      <div fxLayout='column'>
        <div fxFlex='row' class='p-12'>
          <div fxFlex='50%' fxLayoutAlign='start center'>
            <span class="secondary-text">{{ 'PANEL.NOTIFICATIONS'|translate }}</span>
          </div>
          <div fxFlex='50%' fxLayoutAlign='end center'>
            <button mat-raised-button color='primary'
                    *ngIf='notifications.length > 0'
                    matTooltip="{{ 'PANEL.MARK_ALL_READ'|translate }}"
                    (click)='readNotifications()'>
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <span class='p-12 secondary-text' *ngIf='notifications.length === 0'>
          {{ 'PANEL.EMPTY_DATA'|translate }}
        </span>
        <div *cdkVirtualFor="let notification of notifications"
             class='w-100-p p-12 notification-click' (click)="readNotification(notification.id, notification.path);">
          <div fxLayout='column' fxFlexFill>
            <span>{{ notification.title }}</span>
            <span class="secondary-text">{{ notification.createdBy }} {{ notification.description }}</span>
            <div fxLayout='row' class='m-8'>
              <div fxFlex='20%' fxLayoutAlign='start start'>
                <mat-icon class='small-icon'>{{ notification.icon }}</mat-icon>
              </div>
              <div fxFlex='80%' fxLayoutAlign='end center'>
                <span class='secondary-text time'> {{ notification.createdAt }} </span>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
    </mat-list>
  </cdk-virtual-scroll-viewport>
</div>