<div class="header p-24" fxLayout="column" fxLayoutAlign="end center" fxLayout.gt-sm="row"
     fxLayoutAlign.gt-sm="center center">
  <div class="user-info" fxLayout="column" fxLayoutAlign="center start" fxFlex>
    <div class="breadcrumb" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}" fxHide.lt-md>
      <span class="h1">
        {{ 'HEADER.TITLE'|translate }}
      </span>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center end" fxFlex>
    <div class="search-wrapper mt-16 mt-sm-0">
      <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <mat-icon>search</mat-icon>
        <input #filter placeholder="{{ 'HEADER.SEARCH'|translate }}" (keyup)="sendSearchString(filter.value)">
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end end" fxFlex>
    <div class="mt-16 mt-sm-0">
      <button
        mat-raised-button
        color="primary" aria-label="Follow"
        matTooltip="{{ 'HEADER.NEW_EMPLOYEE'|translate }}"
        [routerLink]="['/employee/main/add', complexId]">
        {{ 'HEADER.NEW_EMPLOYEE' | translate }}
      </button>
    </div>
  </div>
</div>
