export const locale = {
  lang: 'es',
  data: {
    'ADD_ACCOUNT': {
      'CREATE_ACCOUNT': 'Crear cuenta',
      'UPDATE_ACCOUNT': 'Actualizar cuenta',
      'NAME': '*Nombre',
      'DESCRIPTION': '*Descripción',
      'SAVE': 'Guardar',
      'UPDATE': 'Actualizar',
      'CANCEL': 'Cancelar',
    },
    'VALIDATIONS': {
      'NAME_REQUIRED': 'Nombre es requerido',
      'DESCRIPTION_REQUIRED': 'Descripción es requerido',
    },
    'MESSAGES': {
      'ACCOUNT_SUCCESS': 'Cuenta creada exitosamente',
      'ACCOUNT_UPDATE_SUCCESS': 'Cuenta actualizada exitosamente',
    },
  }
};
