// Angular
import {
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseConfirmDialogComponent } from '../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Toast
import { ToastService } from '@fuse/utils/toast.service';
// Constants
import { Constants } from '@fuse/utils/constants';;
// Local Service
import { AccountingAccountService } from '../../accounting-account.service';
// Material
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'catalogue-category-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class DetailComponent implements OnInit {
  // Data bindings
  complexId = '';
  categoryId = '';
  info = {
    categoryId: '',
    name: '',
    description: '',
  };
  // Common components
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  constructor(
    private accountingService: AccountingAccountService,
    private activatedRoute: ActivatedRoute,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private matDialog: MatDialog,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.complexId = this.activatedRoute.snapshot.paramMap.get('complexId');
    this.categoryId = this.activatedRoute.snapshot.paramMap.get('categoryId');
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    if (this.categoryId !== '' && this.categoryId !== null && this.categoryId !== undefined) {
      this.getCategoryInfo();
    }
  }

  getCategoryInfo(): void{
    this.accountingService.getCategory (
      this.categoryId, {
        complexId: this.complexId,
      }
    ).subscribe(
      result => {
        if (result.success === true && result.data) {
          this.info.categoryId = result.data.id;
          this.info.name = result.data.name;
          this.info.description = result.data.description;
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      },
      () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  deleteCategory(id: any): void{
    this.confirmDialogRef = this.matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage =
      this.translate.instant('MESSAGES.REMOVE_CATEGORY_ALERT');
    this.confirmDialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.loader.start();
        this.accountingService.removeCategory(
          id, {
            categoryId: id,
          }).subscribe(
          result => {
            if (result.success === true) {
              this.toast.showToast(
                Constants.TOAST_TYPE_SUCCESS,
                this.translate.instant('MESSAGES.REMOVE_CATEGORY_SUCCESS'),
              );
              this.router.navigate(['/catalogue/accounting-account/main']);
              this.loader.stop();
              this.getCategoryInfo();
            } else {
              this.toast.showToast(
                Constants.TOAST_TYPE_ERROR,
                this.translate.instant('MESSAGES.' + result.message),
              );
              this.loader.stop();
            }
          }, () => {
            this.router.navigate(['/pages/error/']);
            this.loader.stop();
          },
        );
      }
    });
  }
}
