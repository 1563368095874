// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Local
import { Global } from '../../../../@fuse/utils/global';

@Injectable({
  providedIn: 'root'
})
export class AccountingAccountService {

  constructor(
    private http: HttpClient,
  ) { }

  // SERVICIOS PARA CATEGORIAS
  public addCategory(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.post(
      `${Global.BASE_API_URL}accounting/category`,
      data,
      httpOptions,
    );
  }

  public changeCategory(categoryId: any, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.put(
      `${Global.BASE_API_URL}accounting/category/${categoryId}`,
      data,
      httpOptions,
    );
  }

  public getCategory(categoryId: any, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}accounting/category/${categoryId}`,
      httpOptions,
    );
  }

  public getListCategories(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}accounting/category`,
      httpOptions,
    );
  }

  public removeCategory(categoryId: any, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.delete(
      `${Global.BASE_API_URL}accounting/category/${categoryId}`,
      httpOptions,
    );
  }

  // SERVICIOS PARA CUENTAS
  public addAccount(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.post(
      `${Global.BASE_API_URL}accounting/account`,
      data,
      httpOptions,
    );
  }

  public changeAccount(accountId: any, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.put(
      `${Global.BASE_API_URL}accounting/account/${accountId}`,
      data,
      httpOptions,
    );
  }

  public getAccount(accountId: any, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}accounting/account/${accountId}`,
      httpOptions,
    );
  }

  public getListAccounts(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}accounting/account`,
      httpOptions,
    );
  }

  public removeAccount(accountId: any, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.delete(
      `${Global.BASE_API_URL}accounting/account/${accountId}`,
      httpOptions,
    );
  }

}
