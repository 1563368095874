export const locale = {
  lang: 'es',
  data: {
    'TABLE': {
      'NO_PROPERTY': 'No. Propiedad',
      'TENANT': 'Inquilino',
      'SPACE': 'Ambiente de propiedad (mt2)',
      'AMENITIES': 'Ambientes extras (mt2)',
      'TOTAL': 'Total (mt2)',
      'QUOTA': 'Cuota',
      'ACTIONS': 'Acciones',
      'STATUS': 'Estado',
      'ACTIVE': 'Propiedad disponible',
      'INACTIVE': 'Propiedad ocupada',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Parámetros erroneos',
      'EDIT': 'Editar distribución',
      'NOT_CONFIGURATION': 'Este complejo no tiene configuraciones de distribución. Debe ir al Web Admin para configurar su espacio haciendo click',
      'CLICK': '  Aquí',
    },
  }
};
