export const locale = {
  lang: 'es',
  data: {
    'NAV': {
      'APPLICATIONS': 'Módulos',
      'DASHBOARD': {
        'TITLE': 'Inicio',
      },
      'CATALOGUE': {
        'TITLE': 'Configuraciones',
        'JOB': {
          'TITLE': 'Puestos',
        },
        'SALARY_DETAIL': {
          'TITLE': 'Detalle de salarios',
        },
        'ACCOUNTING_ACCOUNT': {
          'TITLE': 'Cuentas contables',
        },
      },
      'EMPLOYEE': {
        'TITLE': 'Empleados',
      },
      'DISTRIBUTION': {
        'TITLE': 'Distribución',
      },
      'PETTY_CASH': {
        'TITLE': 'Caja chica',
      },
      'ACCOUNTS_RECEIVABLE': {
        'TITLE': 'Cuentas por cobrar',
      },
      'CONCILIATION': {
        'TITLE': 'Conciliación',
      },
      'INSURANCE': {
        'TITLE': 'Seguros',
      },
      'EXECUTION': {
        'TITLE': 'Ejecución',
      },
      'BUDGET': {
        'TITLE': 'Presupuesto',
      },
      'INCOME': {
        'TITLE': 'Ingresos',
      },
      'EXPENSE': {
        'TITLE': 'Egresos',
      },
      'DEPOSIT': {
        'TITLE': 'Depósito',
      },
      'BANK_ACCOUNT': {
        'TITLE': 'Cuentas de banco',
      },
      'USER': {
        'TITLE': 'Usuarios',
      },
      'REPORT': {
        'TITLE': 'Reportes',
      },
    }
  }
};
