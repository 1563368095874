// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// Fuse
import { FuseSharedModule } from '@fuse/shared.module';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// Local Component
import { PageErrorComponent } from './page-error/page-error.component';

const routes = [
  {
    path: 'error',
    component: PageErrorComponent,
  },
  ];

@NgModule({
  declarations: [PageErrorComponent],
  imports: [
    RouterModule.forChild(routes),
    // Translate
    TranslateModule,
    // Fuse
    FuseSharedModule,
  ],
  exports: [
    PageErrorComponent,
  ],
})
export class PagesModule { }
