export const locale = {
  lang: 'es',
  data: {
    'TABLE': {
      'NAME': 'Nombre',
      'DESCRIPTION': 'Descripción',
      'TYPE': 'Tipo de cuenta',
      'CREATED_BY': 'Creado por',
      'ACTIONS': 'Acciones',
    },
    'MESSAGES': {
      'DETAIL': 'Detalles',
    },
    TYPE_ACCOUNT: {
      INCOME: 'Ingreso',
      EXPENSE: 'Egreso',
    },
  }
};
