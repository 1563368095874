// Angular
import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
// Fuse
import { FuseSharedModule } from '@fuse/shared.module';
// Component local
import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel.component';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// Loader
import { NgxUiLoaderModule } from 'ngx-ui-loader';

@NgModule({
  declarations: [
    QuickPanelComponent,
  ],
  imports: [
    // Translate
    TranslateModule,
    // Fuse
    FuseSharedModule,
    ScrollingModule,
    // Loader
    NgxUiLoaderModule,
    // Material
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    MatTooltipModule,
  ],
  exports: [
    QuickPanelComponent,
  ]
})
export class QuickPanelModule {}
