// Angular
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Local services
import { ToastService } from '@fuse/utils/toast.service';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Material
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// Local services
import { AccountingAccountService } from '../../../accounting-account.service';
// Constants
import { Constants } from '../../../../../../../@fuse/utils/constants';
import { AddAccountComponent } from '../add-account/add-account.component';

@Component({
  selector: 'table-account',
  templateUrl: './table-account.component.html',
  styleUrls: ['./table-account.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class TableAccountComponent implements OnInit, AfterViewInit {
  // Data bindings
  complexId = '';
  categoryId = '';
  accountId = '';
  info = '';
  // Data bindings
  data = [];
  dataSource = new MatTableDataSource();
  displayedColumns = [
    'alias',
    'description',
    'createdBy',
    'actions',
  ];
  // Pagination
  pageNumber = 1;
  itemsPerPage = 10;
  totalItems = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // Sort
  @ViewChild(MatSort) sort: MatSort;
  // Common components
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  // Create account
  payDialogRef: MatDialogRef<AddAccountComponent>;

  constructor(
    private accountingService: AccountingAccountService,
    private activatedRoute: ActivatedRoute,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private matDialog: MatDialog,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.complexId = this.activatedRoute.snapshot.paramMap.get('complexId');
    this.categoryId = this.activatedRoute.snapshot.paramMap.get('categoryId');
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.getAccounts();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  changePage(event: any): void{
    this.pageNumber = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.getAccounts();
  }

  getAccounts(): void {
    this.loader.start();
    this.accountingService.getListAccounts({
      pageNumber: this.pageNumber,
      itemsPerPage: this.itemsPerPage,
      categoryId: this.categoryId,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.info = result.data;
          this.accountId = result.data.id;
          this.data = result.data.data;
          this.dataSource = new MatTableDataSource(result.data.data);
          this.dataSource.sort = this.sort;
          this.totalItems = +result.data.totalItems;
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  newAccount(infoCategory: any): void {
    this.payDialogRef = this.matDialog.open(AddAccountComponent, {
      panelClass: 'dialog-pay',
      disableClose: false
    });
    this.payDialogRef.componentInstance.data = infoCategory;
    this.payDialogRef.afterClosed().subscribe(data => {
      if (data.confirm) {
        this.loader.start();
        this.accountingService.addAccount({
          categoryId: this.categoryId,
          name: data.name,
          description: data.description,
          complexId: this.complexId,
        }).subscribe(
          result => {
            if (result.success === true) {
              this.toast.showToast(
                Constants.TOAST_TYPE_SUCCESS,
                this.translate.instant('MESSAGES.ACCOUNT_SUCCESS'),
              );
              this.loader.stop();
              this.getAccounts();
            } else {
              this.toast.showToast(
                Constants.TOAST_TYPE_ERROR,
                this.translate.instant('MESSAGES.' + result.message),
              );
              this.loader.stop();
            }
            this.loader.stop();
          },
          () => {
            this.router.navigate(['/pages/error/']);
            this.loader.stop();
          }
        );
      }
    });
  }

  updateAccount(infoCategory: any): void {
    this.payDialogRef = this.matDialog.open(AddAccountComponent, {
      disableClose: false
    });
    infoCategory['edit'] = true;
    this.payDialogRef.componentInstance.data = infoCategory;
    this.payDialogRef.afterClosed().subscribe(data => {
      if (data.confirm) {
        this.loader.start();
        this.accountingService.changeAccount(
          infoCategory.id, {
            name: data.name,
            description: data.description,
          }).subscribe(
          result => {
            if (result.success === true) {
              this.getAccounts();
              this.toast.showToast(
                Constants.TOAST_TYPE_SUCCESS,
                this.translate.instant('MESSAGES.ACCOUNT_UPDATE_SUCCESS'),
              );
              this.loader.stop();
              this.getAccounts();
            } else {
              this.toast.showToast(
                Constants.TOAST_TYPE_ERROR,
                this.translate.instant('MESSAGES.' + result.message),
              );
              this.loader.stop();
            }
            this.loader.stop();
          },
          () => {
            this.router.navigate(['/pages/error/']);
            this.loader.stop();
          }
        );
      }
    });
  }

  deleteAccount(id: any): void {
    this.confirmDialogRef = this.matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage =
      this.translate.instant('MESSAGES.REMOVE_ACCOUNT_ALERT');
    this.confirmDialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.loader.start();
        this.accountingService.removeAccount(
          id, {
            accountId: id,
          }).subscribe(
          result => {
            if (result.success === true) {
              this.toast.showToast(
                Constants.TOAST_TYPE_SUCCESS,
                this.translate.instant('MESSAGES.REMOVE_ACCOUNT_SUCCESS'),
              );
              this.loader.stop();
              this.getAccounts();
            } else {
              this.toast.showToast(
                Constants.TOAST_TYPE_ERROR,
                this.translate.instant('MESSAGES.' + result.message),
              );
              this.loader.stop();
            }
          }, () => {
            this.router.navigate(['/pages/error/']);
            this.loader.stop();
          },
        );
      }
    });
  }

}
