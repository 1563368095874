import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import {NullEmptyReplaceTextPipe} from './nullEmptyReplaceText.pipe';

@NgModule({
  declarations: [
    CamelCaseToDashPipe,
    FilterPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    KeysPipe,
    NullEmptyReplaceTextPipe,
  ],
    imports     : [],
  exports: [
    CamelCaseToDashPipe,
    FilterPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    KeysPipe,
    NullEmptyReplaceTextPipe,
  ]
})
export class FusePipesModule
{
}
