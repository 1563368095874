export const locale = {
  lang: 'es',
  data: {
    'HEADER': {
      'CONFIGURATION': 'Detalles',
      'SEARCH': 'Buscar en distribución',
      'TITLE': 'Detalles de distribución de espacio',
    },
  }
};
