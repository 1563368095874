export const locale = {
  lang: 'es',
  data: {
    'TABLE': {
      'ID': 'No.',
      'STATUS': 'Estado',
      'TYPE': 'Tipo',
      'TRANSACTION_DATE': 'Fecha de transacción (BECO)',
      'TRANSACTION_NUMBER': 'No. de transacción (BECO)',
      'TRANSACTION_AMOUNT': 'Monto de transacción (BECO)',
      'TRANSACTION_DATE2': 'Fecha de transacción (Estado de cuenta)',
      'TRANSACTION_NUMBER2': 'No. de transacción (Estado de cuenta)',
      'TRANSACTION_AMOUNT2': 'Monto de transacción (Estado de cuenta)',
      'DEPOSIT': 'Boleta de depósito',
      'DEPOSIT_NUMBER': 'No. Boleta',
      'ACTIONS': 'Acciones',
      'NOT': 'No',
      'YES': 'Sí',
    },
    'MESSAGES': {
      'CONCILIATED': 'Conciliar',
      'REMOVE': 'Borrar',
      'REMOVE_JOB_SUCCESS': 'Trabajo eliminado exitosamente',
      'REMOVE_JOB_ALERT': '¿Está seguro de eliminar este trabajo?',
      'CONCILIATION_TRANSACTION_ALERT': '¿Esta seguro de conciliar esta transacción?',
      'CONCILIATION_TRANSACTION_SUCCESS': 'Conciliación realizada con éxito',
      'EMPTY_DATA': 'No existen registros para mostrar',
    },
    STATUS: {
      PENDING: 'Pendiente',
      CONFLICT: 'Conflicto',
      CONCILIATED: 'Conciliado',
    },
    TYPE: {
      INCOME: 'Ingreso',
      EXPENSE: 'Egreso',
    },
  },
};
