export const locale = {
  lang: 'en',
  data: {
    'PAGES': {
      'TITLE': 'A problem has occurred.',
      'MESSAGE': 'We are working to solve.',
      'BACK_LOGIN': 'Go back to home page',
    },
  }
};
