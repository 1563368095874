export const locale = {
  lang: 'en',
  data: {
    'ADD_CATEGORY': {
      'NEW_CATEGORY': 'Add new category',
      'UPDATE_CATEGORY': 'Update category',
      'NAME': '*Name',
      'DESCRIPTION': '*Description',
      'TYPE': '*Type',
      'INCOME': 'Income',
      'EXPENSE': 'Expense',
      'SAVE': 'Save',
      'CANCEL': 'Cancel',
      'UPDATE': 'Update',
    },
    'VALIDATIONS': {
      'NAME_REQUIRED': 'Name is required',
      'DESCRIPTION_REQUIRED': 'Description is required',
      'TYPE_REQUIRED': 'Type is required',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Bad Parameters',
      'CATEGORY_ALREADY_EXIST': 'Category already exist.',
      'CATEGORY_SUCCESS': 'Category added successfully.',
      'CATEGORY_SUCCESS_UPDATE': 'Category update successfully.',
    },
  }
};
