// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
// Fuse
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Translate
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Environment
import { environment } from '../../../../environments/environment';
import {UserService} from '../../../main/user/user.service';
import { DataService } from '../../../data.service';

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent implements OnInit {
    // Data bindings
    date: Date;
    notifications = [];
    settings: any;
    complex = {
      id: '',
    };

    constructor(
      private dataService: DataService,
      private userService: UserService,
      private fuseTranslationLoaderService: FuseTranslationLoaderService,
      private loader: NgxUiLoaderService,
      private router: Router,
      private translate: TranslateService,
    )
    {
      // Set the defaults
      this.date = new Date();
      this.settings = {
        notify: true,
        cloud: false,
        retro: true
      };
      this.fuseTranslationLoaderService.loadTranslations(english, spanish);
      this.translate.use(environment.DEFAULT_LANG);
      this.translate.onLangChange.subscribe(() => {
        this.getNotifications();
      });

      this.dataService.complex.subscribe(data => {
        this.complex.id = data.id;
        this.getNotifications();
      });
    }

  ngOnInit(): void {
    const lang = localStorage.getItem('lang');
    const token = localStorage.getItem('token');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }

  }

  getNotifications(): void {
    this.loader.start();
    this.userService.getAllNotification({
      lang: this.translate.currentLang,
      complexId: this.complex.id,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.notifications = result.data;
          this.loader.stop();
        } else {
          this.loader.stop();
        }
      },
    );
  }

  readNotifications(): void {
    this.loader.start();
    this.userService.readAllNotification({
      complexId: this.complex.id,
    }).subscribe(
      result => {
        if (result.success === true) {
          window.location.reload();
          this.loader.stop();
        }
      },
    );
  }

  readNotification(notificationId: any, path: any): void {
    this.loader.start();
    this.userService.setReadNotification({
      notificationId: notificationId,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.router.navigate([path]);
          this.loader.stop();
        }
      },
    );
  }

}
