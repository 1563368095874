// Angular
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Material
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// Local services
import { DistributionService } from '../../distribution.service';
import { ToastService } from '@fuse/utils/toast.service';
// Constants
import { Constants } from '@fuse/utils/constants';

@Component({
  selector: 'distribution-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges{
// data
  @Input()
  complexId = '';
  @Input()
  searchString = '';
  // Data bindings
  data = [];
  dataSource = new MatTableDataSource();
  displayedColumns = [
    'propertyNumber',
    'tenant',
    'propertyEnvironmentMt2',
    'propertyAmenityEnvironmentMt2',
    'mt2Total',
    'fee',
    'status',
    'actions',
  ];
  distributionConfig = false;
  // Pagination
  pageNumber = 1;
  itemsPerPage = 10;
  totalItems = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // Sort
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private activatedRoute: ActivatedRoute,
    private distributionService: DistributionService,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.complexId = this.activatedRoute.snapshot.paramMap.get('complexId');
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.getVerify();
    this.getDistributions();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  changePage(event: any): void{
    this.pageNumber = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.getDistributions();
  }

  getVerify(): void {
    this.loader.start();
    this.distributionService.getDistributionVerify(
      this.complexId,
    ).subscribe(
      result => {
        if (result.success === true && result.data) {
          this.distributionConfig = result.data.distribution;
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  getDistributions(): void {
    this.loader.start();
    this.distributionService.getListDistribution({
      complexId: this.complexId,
      pageNumber: this.pageNumber,
      itemsPerPage: this.itemsPerPage,
      searchString: this.searchString,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.data = result.data.data;
          this.dataSource = new MatTableDataSource(result.data.data);
          this.dataSource.sort = this.sort;
          this.totalItems = +result.data.totalItems;
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.complexId && (changes.complexId.currentValue !== ''
      && changes.complexId.currentValue !== changes.complexId.previousValue)) {
      this.getVerify();
      this.getDistributions();
    }
    if (changes.searchString
      && changes.searchString.previousValue !== undefined && this.complexId !== ''
      && (changes.searchString.currentValue !== changes.searchString.previousValue)) {
      this.getDistributions();
    }
  }

  goToUrl(): void {
    window.open('https://admin.beco.tools/es/auth/login/', '_blank');
  }
}
