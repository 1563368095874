// Angular
import {
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Toast
import { ToastService } from '@fuse/utils/toast.service';
// Constants
import { Constants } from '@fuse/utils/constants';
// Local Service
import { EmployeeService } from '../../employee.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'employee-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class DetailComponent implements OnInit {
  // Data bindings
  employeeId = '';
  info = {
    name: '',
    surname: '',
    dpi: '',
    mainPhone: '',
    email: '',
    bond: 0,
    job: '',
    tax: '',
    discount: 0,
    total: 0,
  };
  complexId = '';
  currencyIso: any = {};
  salaryBase: any = {};
  // Reactive form
  detailForm: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private employeeService: EmployeeService,
    private formBuilder: FormBuilder,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.complexId = this.activatedRoute.snapshot.paramMap.get('complexId');
    this.employeeId = this.activatedRoute.snapshot.paramMap.get('employeeId');
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }

    this.detailForm = this.formBuilder.group({
      amountBond:  [{ value: '', disabled: true }],
      amountDesc:  [{ value: '', disabled: true }],
    });

    if (this.employeeId !== '' && this.employeeId !== null && this.employeeId !== undefined) {
       this.getEmployeeInfo();
    }
  }

  getEmployeeInfo(): void{
    this.loader.start();
    this.employeeService.getEmployee(
      this.employeeId, {
        lang: this.translate.currentLang,
    }).subscribe(
      result => {
        if (result.success === true && result.data) {
          this.salaryBase = result.data.salary;
          this.info.name = result.data.name;
          this.info.surname = result.data.surname;
          this.info.dpi = result.data.identificationNumber;
          this.info.mainPhone = result.data.mainPhone;
          this.info.email = result.data.email;
          this.info.job = result.data.jobName;
          this.info.tax = result.data.tax;
          this.info.bond = +result.data.bondsTotal;
          this.info.discount = +result.data.discountsTotal;
          this.info.total = +result.data.total;
          this.currencyIso = result.data.currency.iso;
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      },
      () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

}
