<ngx-ui-loader></ngx-ui-loader>
<div class="page-layout simple tabbed">
  <header-detail></header-detail>
  <div>
  <div class="page-wrapper">
    <div fxLayout="row wrap" class="p-12" fxLayoutAlign="strech strech">
      <mat-card class="w-100-p">
        <div fxLayout="row wrap" class="p-12" fxLayoutAlign="space-between start">
          <div fxFlex.gt-sm="50%" fxFlex.lt-md="100%"
               fxLayoutAlign.gt-sm="start start"
               fxLayoutAlign.lt-md="start start">
            <div fxLayout="column">
              <span class="h1"><strong>{{ 'INFO.EMPLOYEE'|translate }}</strong></span>
              <span class="mt-12"><strong>{{ 'INFO.NAME'|translate }}</strong> {{ info.name }}  {{info.surname}}</span>
              <span class="mt-12"><strong>{{ 'INFO.DPI'|translate }}</strong> {{ info.dpi }}</span>
              <span class="mt-12"><strong>{{ 'INFO.PHONE'|translate }}</strong> {{ info.mainPhone }}</span>
              <span class="mt-12"><strong>{{ 'INFO.JOB'|translate }}</strong> {{ info.job }}</span>
              <span class="mt-12"><strong>{{ 'INFO.EMAIL'|translate }}</strong> {{ info.email }}</span>
              <span class="mt-12"><strong>{{ 'INFO.TAX'|translate }}</strong> {{ info.tax }}</span>
            </div>
          </div>
          <div fxFlex.gt-sm="50%" fxFlex.lt-md="100%"
               fxLayoutAlign.gt-sm="start start"
               fxLayoutAlign.lt-md="start start">
            <div fxLayout="column">
              <span class="h1 mt-12"><strong>{{ 'INFO.SALARY_TITLE'|translate }}</strong></span>
              <span class="mt-12">
                <strong>{{ 'INFO.TOTAL_BOND'|translate }}</strong>
                <span *ngIf="info.bond !== 0">{{ currencyIso }}</span> {{ info.bond|number: '1.2-2' }}
              </span>
              <span class="mt-12">
                <strong>{{ 'INFO.TOTAL_DISCOUNT'|translate }}</strong>
               <span *ngIf="info.discount !== 0">{{ currencyIso }}</span>{{ info.discount|number: '1.2-2' }}
              </span>
              <span class="h2 mt-20">
                <strong>{{ 'INFO.TOTAL'|translate }}
                  <span *ngIf="info.total !== 0">{{ currencyIso }}</span> {{ info.total|number: '1.2-2' }}
                </strong></span>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
    <employee-table-bonds></employee-table-bonds>
    <employee-table-discounts></employee-table-discounts>
  </div>
</div>
