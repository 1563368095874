// Angular
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// Fuse
import { fuseAnimations } from '../../../../../../../@fuse/animations';
import { environment } from '../../../../../../../environments/environment';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Local service
import { AccountingAccountService } from '../../../accounting-account.service';
// Toast
import { ToastService } from '@fuse/utils/toast.service';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'catalogue-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class AddAccountComponent implements OnInit {
  // data
  public data: any;
  // Data bindings
  complexId = '';
  categoryId = '';
  accountId = '';
  // Reactive form
  addForm: FormGroup;
  // Create account
  payDialogRef: MatDialogRef<AddAccountComponent>;

  constructor(
    private accountServices: AccountingAccountService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private matDialog: MatDialog,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<AddAccountComponent>,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.complexId = this.activatedRoute.snapshot.paramMap.get('complexId');
    this.categoryId = this.activatedRoute.snapshot.paramMap.get('categoryId');
    this.accountId = this.activatedRoute.snapshot.paramMap.get('accountId');
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.addForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
    });
    if (this.data.edit && this.data.edit === true) {
      this.assignValues(this.data);
    }
  }

  assignValues(account: any): void{
    this.addForm.controls.name.setValue(account.alias);
    this.addForm.controls.description.setValue(account.description);
  }

  newAccount(confirm: any): void {
    const data = {
      confirm,
      name: this.addForm.controls.name.value,
      description: this.addForm.controls.description.value,
      complexId: this.complexId,
      categoryId: this.categoryId,
    };
    this.dialogRef.close(data);
  }

  updateAccount(confirm: any): void {
    const data = {
      confirm,
      accountId: this.data.id,
      name: this.addForm.controls.name.value,
      description: this.addForm.controls.description.value,
    };
    this.dialogRef.close(data);
  }

}
