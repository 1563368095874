export const locale = {
  lang: 'es',
  data: {
    'HEADER': {
      'NEW_EMPLOYEE': 'Nuevo empleado',
      'SEARCH': 'Buscar en empleados',
      'TITLE': 'Lista de empleados',
    },
  }
};
