<ngx-ui-loader></ngx-ui-loader>
<div class="page-layout simple tabbed">
<header-detail-conciliation></header-detail-conciliation>
  <div class="page-wrapper" *ngIf="data2.length === 0">
    <mat-horizontal-stepper fxHide.lt-md fxShow.gt-sm linear #stepper>
      <mat-step [stepControl]="fileForm" class="w-100-p">
        <div fxLayout="row wrap" class="p-12" fxLayoutAlign="strech strech">
          <div fxLayout="row wrap" class="p-12" fxLayoutAlign="space-between center" fxFill>
            <div fxFlex.gt-sm="50%" fxFlex.lt-md="100%"
                 fxLayoutAlign.gt-sm="start center"
                 fxLayoutAlign.lt-md="start start">
              <div class="w-75-p row">
                <ng-template matStepLabel>{{'ADD.TITLE1'|translate}}</ng-template>
                <ngx-dropzone [multiple]="false" (change)="onSelect($event)" class='w-100-p'>
                  <ngx-dropzone-label>{{ 'ADD.FILE'|translate }}</ngx-dropzone-label>
                  <ngx-dropzone-preview [removable]="true" (removed)="onRemove($event)" *ngIf='file.file !== null'>
                    <ngx-dropzone-label>{{ file.file.name }}</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
                <button mat-raised-button color="primary" class="mt-12"
                        [disabled]="file.file === null" matTooltip="{{ 'ADD.NEXT'|translate }}"
                        (click)="fileColumns();" matStepperNext>{{ 'ADD.NEXT'|translate }}
                </button>
              </div>
            </div>
            <div class="12" fxFlex.lt-md="100%" fxFlex.gt-sm="44%" fxLayoutAlign="end" fxLayout="row">
              <ul><strong>{{ 'INSTRUCTIONS.TEXT1'|translate }}</strong>
                <li>{{ 'INSTRUCTIONS.TEXT2'|translate }}</li>
                <li>{{ 'INSTRUCTIONS.TEXT3'|translate }}</li>
                <li>{{ 'INSTRUCTIONS.TEXT4'|translate }}</li>
                <li>{{ 'INSTRUCTIONS.TEXT5'|translate }}</li>
                <li>{{ 'INSTRUCTIONS.TEXT6'|translate }}</li>
                <br>
                <span>{{ 'INSTRUCTIONS.TEXT7'|translate }}</span><br>
                <button mat-raised-button color="primary" class="mt-12"
                        matTooltip="{{ 'INSTRUCTIONS.TUTORIAL'|translate }}">{{ 'INSTRUCTIONS.TUTORIAL'|translate }}
                </button>
              </ul>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step [stepControl]="loadForm">
        <form [formGroup]="loadForm">
          <div fxLayout="row wrap" class="p-12" fxLayoutAlign="space-between" fxFill>
            <div fxFlex.lt-md="100%" fxFlex.gt-sm="44%" fxLayoutAlign="start" fxLayout="column">
              <div>
                <mat-form-field appearance="outline" class="w-100-p">
                  <mat-label>{{ 'ADD.NO_DOCUMENT'|translate }}</mat-label>
                  <mat-select formControlName="document">
                    <mat-option value="A">{{ 'ADD.SELECT'|translate }}</mat-option>
                    <div *ngFor="let columns of data">
                    <mat-option *ngFor="let item of columns; let i = index" [value]="i">{{ item }}</mat-option>
                    </div>
                  </mat-select>
                  <mat-error *ngIf="loadForm.get('document').hasError('required')">
                    {{ 'VALIDATIONS.NO_COLUMN'|translate }}
                  </mat-error>
                  <mat-error *ngIf="!loadForm.get('document').hasError('required') &&
                      loadForm.get('document').hasError('pattern')">
                    {{ 'VALIDATIONS.INVALID_REQUIRED'|translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100-p">
                  <mat-label>{{ 'ADD.NO_CREDIT'|translate }}</mat-label>
                  <mat-select formControlName="credit">
                    <mat-option value="A">{{ 'ADD.SELECT'|translate }}</mat-option>
                    <div *ngFor="let columns of data">
                      <mat-option *ngFor="let item of columns; let i = index" [value]="i">{{ item }}</mat-option>
                    </div>
                  </mat-select>
                  <mat-error *ngIf="loadForm.get('credit').hasError('required')">
                    {{ 'VALIDATIONS.NO_COLUMN'|translate }}
                  </mat-error>
                  <mat-error *ngIf="!loadForm.get('credit').hasError('required') &&
                      loadForm.get('credit').hasError('pattern')">
                    {{ 'VALIDATIONS.INVALID_REQUIRED'|translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100-p">
                  <mat-label>{{ 'ADD.NO_DEBIT'|translate }}</mat-label>
                  <mat-select formControlName="debit">
                    <mat-option value="A">{{ 'ADD.SELECT'|translate }}</mat-option>
                    <div *ngFor="let columns of data">
                      <mat-option *ngFor="let item of columns; let i = index" [value]="i">{{ item }}</mat-option>
                    </div>
                  </mat-select>
                  <mat-error *ngIf="loadForm.get('debit').hasError('required')">
                    {{ 'VALIDATIONS.NO_COLUMN'|translate }}
                  </mat-error>
                  <mat-error *ngIf="!loadForm.get('debit').hasError('required') &&
                      loadForm.get('debit').hasError('pattern')">
                    {{ 'VALIDATIONS.INVALID_REQUIRED'|translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100-p">
                  <mat-label>{{ 'ADD.NO_DATE'|translate }}</mat-label>
                  <mat-select formControlName="date">
                    <mat-option value="A">{{ 'ADD.SELECT'|translate }}</mat-option>
                    <div *ngFor="let columns of data">
                      <mat-option *ngFor="let item of columns; let i = index" [value]="i">{{ item }}</mat-option>
                    </div>
                  </mat-select>
                  <mat-error *ngIf="loadForm.get('date').hasError('required')">
                    {{ 'VALIDATIONS.NO_COLUMN'|translate }}
                  </mat-error>
                  <mat-error *ngIf="!loadForm.get('date').hasError('required') &&
                      loadForm.get('date').hasError('pattern')">
                    {{ 'VALIDATIONS.INVALID_REQUIRED'|translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="mt-12">
                <button color="accent" matTooltip="{{ 'ADD.BACK'|translate }}"
                        mat-raised-button matStepperPrevious>{{ 'ADD.BACK'|translate }}
                </button>
                <button class="ml-8" mat-raised-button
                        [disabled]="loadForm.invalid" matTooltip="{{ 'ADD.FINISH'|translate }}"
                        (click)="loadFile();" color="primary" matStepperNext>{{ 'ADD.FINISH'|translate }}
                </button>
              </div>
            </div>
            <div fxFlex.lt-md="100%" fxFlex.gt-sm="44%">
              <div fxLayoutAlign.lt-lg="start start" fxLayoutAlign.gt-md="start start"  fxLayout="column">
                <ul><strong>{{ 'INSTRUCTIONS.TEXT1'|translate }}</strong>
                  <li>{{ 'INSTRUCTIONS.TEXT8'|translate }}</li>
                  <li>{{ 'INSTRUCTIONS.TEXT9'|translate }}</li>
                  <li>{{ 'INSTRUCTIONS.TEXT10'|translate }}</li>
                  <li>{{ 'INSTRUCTIONS.TEXT11'|translate }}</li>
                  <li>{{ 'INSTRUCTIONS.TEXT12'|translate }}</li>
                </ul>
              </div>
            </div>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
    <mat-vertical-stepper fxHide.gt-sm fxShow.lt-md linear #stepper>
      <mat-step [stepControl]="fileForm">
        <ul><strong>{{ 'INSTRUCTIONS.TEXT1'|translate }}</strong>
          <li>{{ 'INSTRUCTIONS.TEXT2'|translate }}</li>
          <li>{{ 'INSTRUCTIONS.TEXT3'|translate }}</li>
          <li>{{ 'INSTRUCTIONS.TEXT4'|translate }}</li>
          <li>{{ 'INSTRUCTIONS.TEXT5'|translate }}</li>
          <li>{{ 'INSTRUCTIONS.TEXT6'|translate }}</li>
          <br>
          <span>{{ 'INSTRUCTIONS.TEXT7'|translate }}</span><br>
          <button mat-raised-button color="primary" class="mt-12"
                  matTooltip="{{ 'INSTRUCTIONS.TUTORIAL'|translate }}">{{ 'INSTRUCTIONS.TUTORIAL'|translate }}
          </button>
        </ul>
        <form [formGroup]="fileForm">
          <ng-template matStepLabel>{{'ADD.TITLE1'|translate}}</ng-template>
          <ngx-dropzone [multiple]="false" (change)="onSelect($event)" class='mt-16 w-75-p'>
            <ngx-dropzone-label>{{ 'ADD.FILE'|translate }}</ngx-dropzone-label>
            <ngx-dropzone-preview [removable]="true" (removed)="onRemove($event)" *ngIf='file.file !== null'>
              <ngx-dropzone-label>{{ file.file.name }}</ngx-dropzone-label>
            </ngx-dropzone-preview>
          </ngx-dropzone>
          <div>
            <button mat-raised-button color="primary" class="mt-12"
              [disabled]="file.file === null" matTooltip="{{ 'ADD.NEXT'|translate }}"
              (click)="fileColumns();" matStepperNext>{{ 'ADD.NEXT'|translate }}
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="loadForm">
        <form [formGroup]="loadForm">
          <div fxLayoutAlign.lt-lg="start start" fxLayoutAlign.gt-md="start start"  fxLayout="column">
            <ul><strong>{{ 'INSTRUCTIONS.TEXT1'|translate }}</strong>
              <li>{{ 'INSTRUCTIONS.TEXT8'|translate }}</li>
              <li>{{ 'INSTRUCTIONS.TEXT9'|translate }}</li>
              <li>{{ 'INSTRUCTIONS.TEXT10'|translate }}</li>
              <li>{{ 'INSTRUCTIONS.TEXT11'|translate }}</li>
              <li>{{ 'INSTRUCTIONS.TEXT12'|translate }}</li>
            </ul>
          </div>
          <div>
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>{{ 'ADD.NO_DOCUMENT'|translate }}</mat-label>
              <mat-select formControlName="document">
                <mat-option value="A">{{ 'ADD.SELECT'|translate }}</mat-option>
                <div *ngFor="let columns of data">
                  <mat-option *ngFor="let item of columns; let i = index" [value]="i">{{ item }}</mat-option>
                </div>
              </mat-select>
              <mat-error *ngIf="loadForm.get('document').hasError('required')">
                {{ 'VALIDATIONS.NO_COLUMN'|translate }}
              </mat-error>
              <mat-error *ngIf="!loadForm.get('document').hasError('required') &&
                      loadForm.get('document').hasError('pattern')">
                {{ 'VALIDATIONS.INVALID_REQUIRED'|translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>{{ 'ADD.NO_CREDIT'|translate }}</mat-label>
              <mat-select formControlName="credit">
                <mat-option value="A">{{ 'ADD.SELECT'|translate }}</mat-option>
                <div *ngFor="let columns of data">
                  <mat-option *ngFor="let item of columns; let i = index" [value]="i">{{ item }}</mat-option>
                </div>
              </mat-select>
              <mat-error *ngIf="loadForm.get('credit').hasError('required')">
                {{ 'VALIDATIONS.NO_COLUMN'|translate }}
              </mat-error>
              <mat-error *ngIf="!loadForm.get('credit').hasError('required') &&
                      loadForm.get('credit').hasError('pattern')">
                {{ 'VALIDATIONS.INVALID_REQUIRED'|translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>{{ 'ADD.NO_DEBIT'|translate }}</mat-label>
              <mat-select formControlName="debit">
                <mat-option value="A">{{ 'ADD.SELECT'|translate }}</mat-option>
                <div *ngFor="let columns of data">
                  <mat-option *ngFor="let item of columns; let i = index" [value]="i">{{ item }}</mat-option>
                </div>
              </mat-select>
              <mat-error *ngIf="loadForm.get('debit').hasError('required')">
                {{ 'VALIDATIONS.NO_COLUMN'|translate }}
              </mat-error>
              <mat-error *ngIf="!loadForm.get('debit').hasError('required') &&
                      loadForm.get('debit').hasError('pattern')">
                {{ 'VALIDATIONS.INVALID_REQUIRED'|translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>{{ 'ADD.NO_DATE'|translate }}</mat-label>
              <mat-select formControlName="date">
                <mat-option value="A">{{ 'ADD.SELECT'|translate }}</mat-option>
                <div *ngFor="let columns of data">
                  <mat-option *ngFor="let item of columns; let i = index" [value]="i">{{ item }}</mat-option>
                </div>
              </mat-select>
              <mat-error *ngIf="loadForm.get('date').hasError('required')">
                {{ 'VALIDATIONS.NO_COLUMN'|translate }}
              </mat-error>
              <mat-error *ngIf="!loadForm.get('date').hasError('required') &&
                      loadForm.get('date').hasError('pattern')">
                {{ 'VALIDATIONS.INVALID_REQUIRED'|translate }}
              </mat-error>
            </mat-form-field>
            <div>
              <button color="accent" matTooltip="{{ 'ADD.BACK'|translate }}"
                      mat-raised-button matStepperPrevious>{{ 'ADD.BACK'|translate }}
              </button>
              <button class="ml-8" mat-raised-button
                [disabled]="loadForm.invalid" matTooltip="{{ 'ADD.FINISH'|translate }}"
                (click)="loadFile();" color="primary" matStepperNext>{{ 'ADD.FINISH'|translate }}
              </button>
            </div>
          </div>
        </form>
      </mat-step>
    </mat-vertical-stepper>
  </div>
  <div *ngIf="data2.length !== 0">
  <table-conciliation-trans></table-conciliation-trans>
  </div>
</div>
