export const locale = {
  lang: 'es',
  data: {
    'TABLE': {
      'TITLE': 'Cuentas de categoría',
      'NAME': 'Nombre',
      'DESCRIPTION': 'Descripción',
      'CREATED_BY': 'Creado por',
      'ACTIONS': 'Acciones',
      'ADD_ACCOUNT': 'Agregar cuenta',
      'EMPTY': 'No existen cuentas de categoria para mostrar',
    },
    'MESSAGES': {
      'REMOVE': 'Eliminar cuenta',
      'EDIT': 'Editar cuenta',
      'REMOVE_ACCOUNT_SUCCESS': 'Cuenta eliminada con éxito',
      'REMOVE_ACCOUNT_ALERT': '¿Está seguro de eliminar esta cuenta?',
      'ACCOUNT_UPDATE_SUCCESS': 'Cuenta actualizada cone éxito',
    },
  }
};
