// Angular
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
// Material
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
// Fuse
import {FuseSharedModule} from '@fuse/shared.module';
// Component local
import {FooterComponent} from 'app/layout/components/footer/footer.component';

@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    RouterModule,

    MatButtonModule,
    MatIconModule,
    MatToolbarModule,

    FuseSharedModule
  ],
  exports: [
    FooterComponent
  ]
})
export class FooterModule {
}
