<ngx-ui-loader></ngx-ui-loader>
<div class="page-layout simple tabbed">
  <header-add></header-add>
  <div class="page-wrapper">
    <div class="p-12" fxFlexFill fxLayout="row" fxLayoutAlign="space-between start" id="add">
      <div fxFlex.gt-sm="50%" fxFlex.lt-md="100%">
        <mat-card fxLayout="column">
          <div id="add-form">
            <form [formGroup]="addForm" class="mt-12" name="addForm" novalidate>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{ 'ADD.NAME'|translate }}</mat-label>
                <input formControlName="name" matInput>
                <mat-error>
                  {{ 'VALIDATIONS.NAME_REQUIRED'|translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{ 'ADD.SURNAME'|translate }}</mat-label>
                <input formControlName="surname" matInput>
                <mat-error>
                  {{ 'VALIDATIONS.SURNAME_REQUIRED'|translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{ 'ADD.DPI'|translate }}</mat-label>
                <input formControlName="dpi" matInput>
                <mat-error *ngIf="addForm.get('dpi').hasError('required')">
                  {{ 'VALIDATIONS.DPI_REQUIRED'|translate }}
                </mat-error>
                <mat-error *ngIf="!addForm.get('dpi').hasError('required') &&
                      addForm.get('dpi').hasError('pattern')">
                  {{ 'VALIDATIONS.NUMBER_REQUIRED'|translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{ 'ADD.JOB'|translate }}</mat-label>
                <mat-select formControlName="job">
                  <mat-option *ngFor="let job of jobs" value="{{ job.id }}">{{ job.name }}</mat-option>
                </mat-select>
                <mat-error>
                  {{ 'VALIDATIONS.JOB_REQUIRED'|translate }}
                </mat-error>
              </mat-form-field>
              <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="16px grid">
                <div fxFlex.gt-sm="30%" fxFlex.lt-md="100%">
                  <mat-form-field appearance="outline" class="w-100-p">
                    <mat-label>{{ 'ADD.COUNTRY_CODE'|translate }}</mat-label>
                    <mat-select formControlName="countryCode">
                      <mat-option *ngFor="let country of codes" value="{{ country.id }}">{{ country.code }} </mat-option>
                    </mat-select>
                    <mat-error>
                      {{ 'VALIDATIONS.COUNTRY_CODE_REQUIRED'|translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex.gt-sm="70%" fxFlex.lt-md="100%">
                  <mat-form-field appearance="outline" class="w-100-p">
                    <mat-label>{{ 'ADD.PHONE'|translate }}</mat-label>
                    <input matInput formControlName="phone_main">
                    <mat-error *ngIf="addForm.get('phone_main').hasError('required')">
                      {{ 'VALIDATIONS.PHONE_REQUIRED'|translate }}
                    </mat-error>
                    <mat-error *ngIf="!addForm.get('phone_main').hasError('required') &&
                      addForm.get('phone_main').hasError('pattern')">
                      {{ 'VALIDATIONS.NUMBER_REQUIRED'|translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{ 'ADD.EMAIL'|translate }}</mat-label>
                <input formControlName="email" matInput>
                <mat-error *ngIf=" addForm.get('email').hasError('email')">
                  {{ 'VALIDATIONS.BAD_EMAIL_FORMAT'|translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{ 'ADD.TAX'|translate }}</mat-label>
                <input formControlName="tax" matInput>
                <mat-error *ngIf=" addForm.get('tax').hasError('pattern')">
                  {{ 'VALIDATIONS.NUMBER_REQUIRED'|translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{ 'ADD.SALARY'|translate }} ({{ complexCurrency }})</mat-label>
                <input formControlName="baseSalary" matInput>
                <mat-error *ngIf="addForm.get('baseSalary').hasError('required')">
                  {{ 'VALIDATIONS.SALARY_REQUIRED'|translate }}
                </mat-error>
                <mat-error *ngIf="!addForm.get('baseSalary').hasError('required') &&
                      addForm.get('baseSalary').hasError('pattern')">
                  {{ 'VALIDATIONS.NUMBER_REQUIRED'|translate }}
                </mat-error>
              </mat-form-field>
              <div class="employee" *ngFor="let bond of salaryBase.bonds">
              <span class="h1">{{ bond.name }}</span><br>
              <span class="mt-12">{{ bond.description }}</span>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{ 'ADD.BOND'|translate }}</mat-label>
                <input [value]="bond.amount" (input)="bond.amount = $event.target.value" matInput>
              </mat-form-field>
              </div>
              <div class="contract" *ngFor="let discount of salaryBase.discounts">
              <span class="h1">{{ discount.name }}</span><br>
              <span class="mt-12">{{ discount.description }}</span>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{ 'ADD.DISCOUNT'|translate }}</mat-label>
                <input [value]="discount.amount" (input)="discount.amount = $event.target.value" matInput>
              </mat-form-field>
              </div>
              <button *ngIf='employeeId == null' [disabled]="addForm.invalid" color="primary" (click)='newEmployee();'
                      mat-raised-button>
                {{ 'ADD.SAVE'|translate }}
              </button>
              <button *ngIf='complexId && employeeId !== null' (click)='updateEmployee();'
                      [disabled]="addForm.invalid" color="primary" mat-raised-button>
                {{ 'ADD.UPDATE'|translate }}
              </button>
              <button [routerLink]="'/employee/main'" class="ml-8" color="accent" mat-raised-button>
                {{ 'ADD.CANCEL'|translate }}
              </button>
            </form>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
