export const locale = {
  lang: 'en',
  data: {
    'PANEL': {
      'NOTIFICATIONS': 'Notifications',
      'EMPTY_DATA': 'There are no notifications to show',
      'MARK_ALL_READ': 'Mark all notification as read',
    },
  }
};
