// Angular
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Constants
import { Constants } from '@fuse/utils/constants';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Local services
import { AccountingAccountService } from '../../accounting-account.service';
import { ToastService } from '@fuse/utils/toast.service';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Material
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'category-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges {
// data
  @Input()
  complexId = '';
  // Data bindings
  data = [];
  displayedColumns = [
    'alias',
    'description',
    'type',
    'createdBy',
    'actions',
  ];
  dataSource = new MatTableDataSource();
  // Pagination
  pageNumber = 1;
  itemsPerPage = 10;
  totalItems = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // Sort
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private accountingService: AccountingAccountService,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }

    if (this.complexId !== '' && this.complexId !== null && this.complexId !== undefined) {
      this.getCategories();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  changePage(event: any): void{
    this.pageNumber = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.getCategories();
  }

  getCategories(): void {
    this.loader.start();
    this.accountingService.getListCategories({
      pageNumber: this.pageNumber,
      itemsPerPage: this.itemsPerPage,
      complexId: this.complexId,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.data = result.data.data;
          this.dataSource = new MatTableDataSource(result.data.data);
          this.dataSource.sort = this.sort;
          this.totalItems = +result.data.totalItems;
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.complexId && (changes.complexId.currentValue !== ''
      && changes.complexId.currentValue !== changes.complexId.previousValue)) {
      this.getCategories();
    }
  }

}
