// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// Local component
import { DetailComponent } from './main/detail/detail.component';
import { HeaderComponent } from './main/header/header.component';
import { HeaderDetailComponent } from './main/detail/header-detail/header-detail.component';
import { MainComponent } from './main/main.component';
import { TableComponent } from './main/table/table.component';
import { TableConciliationTransComponent } from './main/detail/table-conciliation-trans/table-conciliation-trans.component';
// Fuse
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
// Loader
import { NgxUiLoaderModule } from 'ngx-ui-loader';
// Common component
import { CommonComponentModule } from '../common-component/common-component.module';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxDropzoneModule } from 'ngx-dropzone';
// Guard
import { AuthGuardService as AuthGuard } from '../oauth/auth-guard.service';

const routes = [
  {
    path: 'main',
    data: {breadcrumb: 'Admin. Conciliación'},
    children: [
      {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Transacciones'},
      },
      {
        path: 'detail/:complexId/:conciliatedId/:accountId',
        component: DetailComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Detalles'},
      }
      ],
  },
];

@NgModule({
  declarations: [
    DetailComponent,
    HeaderComponent,
    HeaderDetailComponent,
    MainComponent,
    TableComponent,
    TableConciliationTransComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    // Translate
    TranslateModule,
    // Loader
    NgxUiLoaderModule,
    // Fuse
    FuseSharedModule,
    FuseWidgetModule,
    // Common component
    CommonComponentModule,
    // Material
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTooltipModule,
    NgxDropzoneModule,
  ],
  exports: [
    MainComponent,
  ],
})
export class ConciliationModule { }
