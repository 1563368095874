// Angular
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Constants
import { Constants } from '@fuse/utils/constants';
// Toast
import { ToastService } from '@fuse/utils/toast.service';
// Local service
import { AccountingAccountService } from '../../../accounting-account.service';

@Component({
  selector: 'add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class AddCategoryComponent implements OnInit {
  // Data bindings
  complexId = '';
  categoryId = '';
  // Reactive form
  addForm: FormGroup;

  constructor(
    private accountingService: AccountingAccountService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.complexId = this.activatedRoute.snapshot.paramMap.get('complexId');
    this.categoryId = this.activatedRoute.snapshot.paramMap.get('categoryId');
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.addForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      type: ['', Validators.required],
    });
    if (this.categoryId !== '' && this.categoryId !== null && this.categoryId !== undefined) {
      this.getCategoryInfo();
    }
  }

  newCategory(): void{
    this.loader.start();
    this.accountingService.addCategory({
      name: this.addForm.controls.name.value,
      description: this.addForm.controls.description.value,
      type: this.addForm.controls.type.value,
      complexId: this.complexId,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.toast.showToast(
            Constants.TOAST_TYPE_SUCCESS,
            this.translate.instant('MESSAGES.CATEGORY_SUCCESS'),
          );
          this.router.navigate(['/catalogue/accounting-account/main']);
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, error => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      },
    );
  }

  updateCategory(): void{
    this.loader.start();
    this.accountingService.changeCategory(
      this.categoryId, {
        name: this.addForm.controls.name.value,
        description: this.addForm.controls.description.value,
      }).subscribe(
      result => {
        if (result.success === true) {
          this.toast.showToast(
            Constants.TOAST_TYPE_SUCCESS,
            this.translate.instant('MESSAGES.CATEGORY_SUCCESS_UPDATE'),
          );
          this.router.navigate(['/catalogue/accounting-account/main/detail-category', this.complexId, this.categoryId]);
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, error => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      },
    );
  }

  getCategoryInfo(): void{
    this.accountingService.getCategory (
      this.categoryId, {
        complexId: this.complexId,
      }
    ).subscribe(
      result => {
        if (result.success === true && result.data) {
          this.addForm.controls.name.setValue(result.data.name);
          this.addForm.controls.description.setValue(result.data.description);
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      },
      () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

}
