// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from '../../header/i18n/en';
import { locale as spanish } from '../../header/i18n/es';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'header-add',
  templateUrl: './header-add.component.html',
  styleUrls: ['./header-add.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class HeaderAddComponent implements OnInit {
employeeId = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.employeeId = this.activatedRoute.snapshot.paramMap.get('employeeId');
  }

}
