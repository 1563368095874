// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// Local component
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// Loader
import { NgxUiLoaderModule } from 'ngx-ui-loader';
// Fuse
import { FuseSharedModule } from '@fuse/shared.module';
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const routes = [
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
];

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    // Translate
    TranslateModule,
    // Material
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSnackBarModule,
    // Fuse
    FuseSharedModule,
    // Loader
    NgxUiLoaderModule,
  ],
  exports: [
    ForgotPasswordComponent,
    LoginComponent,
  ],
})
export class OauthModule {
}
