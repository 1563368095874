export const locale = {
  lang: 'es',
  data: {
    'PAGES': {
      'TITLE': 'Ha ocurrido un problema!',
      'MESSAGE': 'Estamos trabajando para resolverlo.',
      'BACK_LOGIN': 'Regresar a Inicio',
    },
  }
};
