export const locale = {
  lang: 'es',
  data: {
    'ADD_CATEGORY': {
      'NEW_CATEGORY': 'Agregar nueva categoría',
      'UPDATE_CATEGORY': 'Actualizar categoría',
      'NAME': '*Nombre',
      'DESCRIPTION': '*Descripción',
      'TYPE': '*Tipo',
      'INCOME': 'Ingreso',
      'EXPENSE': 'Gasto',
      'SAVE': 'Guardar',
      'UPDATE': 'Actualizar',
      'CANCEL': 'Cancelar',
    },
    'VALIDATIONS': {
      'NAME_REQUIRED': 'Nombre es requerido',
      'DESCRIPTION_REQUIRED': 'Descripción es requerido',
      'TYPE_REQUIRED': 'Tipo es requerido',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Parámetros erroneos.',
      'CATEGORY_ALREADY_EXIST': 'La categoría ya existe.',
      'CATEGORY_SUCCESS': 'Categoría agregado exitosamente.',
      'CATEGORY_SUCCESS_UPDATE': 'Categoría actualizada exitosamente.',
    },
  }
};
