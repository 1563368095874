export const locale = {
  lang: 'en',
  data: {
    'INFO': {
      'DETAIL': 'Category details',
      'EDIT': 'Category edit',
      'REMOVE': 'Category delete',
      'BACK': 'Back',
      'NAME': 'Name: ',
      'DESCRIPTION': 'Description',
      'INFORMATION': 'Information',
      'ACTIONS': 'Actions',
      'ADD_ACCOUNT': 'Create account'
    },
    'MESSAGES': {
      'REMOVE_CATEGORY_SUCCESS': 'Category successfully removed',
      'REMOVE_CATEGORY_ALERT': 'Are you sure to remove category?',
    },
  }
};
