export const locale = {
  lang: 'es',
  data: {
    'INFO': {
      'DETAIL': 'Detalles de categoría',
      'EDIT': 'Editar categoría',
      'REMOVE': 'Eliminar categoría',
      'BACK': 'Regresar',
      'NAME': 'Nombre: ',
      'DESCRIPTION': 'Descripción',
      'INFORMATION': 'Información',
      'ACTIONS': 'Acciones',
      'ADD_ACCOUNT': 'Crear cuenta',
    },
    'MESSAGES': {
      'REMOVE_CATEGORY_SUCCESS': 'Categoría eliminada exitosamente',
      'REMOVE_CATEGORY_ALERT': '¿Está seguro de eliminar esta categoría?',
    },
  }
};
