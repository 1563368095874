<div class="header p-24" fxLayout="column" fxLayoutAlign="end center" fxLayout.gt-sm="row"
     fxLayoutAlign.gt-sm="center center">
  <div class="user-info" fxLayout="column" fxLayoutAlign="center start" fxFlex>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
         fxLayoutAlign.gt-sm="start center" class='mt-32'>
      <div class="name" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        <span *ngIf='employeeId == null'>{{ 'ADD.NEW_EMPLOYEE'|translate }}</span>
        <span *ngIf='employeeId !== null'>{{ 'ADD.UPDATE_EMPLOYEE'|translate }}</span>
      </div>
    </div>
  </div>
</div>