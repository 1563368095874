export const locale = {
  lang: 'en',
  data: {
    'HEADER': {
      'CONFIGURATION': 'Details',
      'SEARCH': 'Search distribution',
      'TITLE': 'Space distribution details',
    },
  }
};
