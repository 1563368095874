export const locale = {
  lang: 'en',
  data: {
    'INFO': {
      'DETAIL': 'Details of employee',
      'EMPLOYEE': 'Information',
      'NAME': 'Name: ',
      'DPI': 'DPI: ',
      'PHONE': 'Phone: ',
      'SALARY_TITLE': 'Salary detail: ',
      'SALARY': 'Salary: ',
      'BOND': 'Bond: ',
      'DISCOUNT': 'Discount: ',
      'JOB': 'Job: ',
      'EMAIL': 'Email: ',
      'TAX': 'Tax: ',
      'EDIT': 'Edit employee',
      'BACK': 'Back',
      'TOTAL': 'Total earn: ',
      'TOTAL_DISCOUNT': 'Total discounts: ',
      'TOTAL_BOND': 'Total bonds: ',
      'ID': 'No.',
      'DESCRIPTION': 'Description',
      'AMOUNT': 'Amount',
    },
  }
};
