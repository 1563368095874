<div class="header p-24" fxLayout="column" fxLayoutAlign="end center" fxLayout.gt-sm="row"
     fxLayoutAlign.gt-sm="center center">
  <div class="user-info" fxLayout="column" fxLayoutAlign="center start" fxFlex>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
         fxLayoutAlign.gt-sm="start center" class='mt-32'>
      <div class="name" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        <span>{{ 'INFO.DETAIL'|translate }}</span>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end end" fxFlex>
    <div class="mt-16 mt-sm-0">
      <button
        mat-raised-button
        color="primary" aria-label="Follow"
        matTooltip="{{ 'INFO.EDIT'|translate }}"
        [routerLink]="['/employee/main/edit', complexId, employeeId]">
        {{ 'INFO.EDIT' | translate }}
      </button>
    </div>
  </div>
</div>
