<ngx-ui-loader></ngx-ui-loader>
<div class="page-layout simple tabbed">
  <header-add-category></header-add-category>
  <div class="page-wrapper">
    <div class="p-12" fxFlexFill fxLayout="row" fxLayoutAlign="space-between start" id="add">
      <div fxFlex.gt-sm="50%" fxFlex.lt-md="100%">
        <mat-card fxLayout="column">
          <div id="add-form">
            <form [formGroup]="addForm" class="mt-12" name="addForm" novalidate>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{ 'ADD_CATEGORY.NAME'|translate }}</mat-label>
                <input formControlName="name" matInput>
                <mat-error>
                  {{ 'VALIDATIONS.NAME_REQUIRED'|translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{ 'ADD_CATEGORY.DESCRIPTION'|translate }}</mat-label>
                <input formControlName="description" matInput>
                <mat-error>
                  {{ 'VALIDATIONS.DESCRIPTION_REQUIRED'|translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100-p" *ngIf="categoryId == null">
                <mat-label>{{ 'ADD_CATEGORY.TYPE'|translate }}</mat-label>
                <mat-select formControlName="type">
                  <mat-option value="INCOME">{{ 'ADD_CATEGORY.INCOME'|translate }}</mat-option>
                  <mat-option value="EXPENSE">{{ 'ADD_CATEGORY.EXPENSE'|translate }}</mat-option>
                </mat-select>
                <mat-error>
                  {{ 'VALIDATIONS.TYPE_REQUIRED'|translate }}
                </mat-error>
              </mat-form-field>
              <button *ngIf='categoryId == null' [disabled]="addForm.invalid" color="primary" (click)='newCategory();'
                      mat-raised-button>
                {{ 'ADD_CATEGORY.SAVE'|translate }}
              </button>
              <button *ngIf='categoryId == null' [routerLink]="'/catalogue/accounting-account/main'"
                      class="ml-8" color="accent" mat-raised-button>
                {{ 'ADD_CATEGORY.CANCEL'|translate }}
              </button>
              <button *ngIf='categoryId !== null' (click)='updateCategory();'
                      [disabled]="addForm.invalid || addForm.untouched" color="primary" mat-raised-button>
                {{ 'ADD_CATEGORY.UPDATE'|translate }}
              </button>
              <button *ngIf='categoryId !== null'
                      [routerLink]="['/catalogue/accounting-account/main/detail-category', complexId, categoryId]"
                      class="ml-8" color="accent" mat-raised-button>
                {{ 'ADD_CATEGORY.CANCEL'|translate }}
              </button>
            </form>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
