export const locale = {
  lang: 'en',
  data: {
    'TABLE': {
      'NO': 'No. Transaction',
      'NO_DOCUMENT': 'Name document',
      'CREATED_BY': 'Created by',
      'DATE': 'Date',
      'DESCRIPTION': 'Description',
      'TYPE_DOCUMENT': 'Status',
      'ISSUING': 'Issuing',
      'EXPENSE': 'Expense',
      'DEPOSIT': 'Deposit',
      'NAME': 'Name',
      'ACTIONS': 'Actions',
      'START_DATE': 'Start date',
      'MONTH': 'Month',
      'YEAR': 'Year',
      'STATUS': 'Status',
      'PAID': 'Paid',
      'REJECTED': 'Rejected',
      'PENDING': 'Pending',
      'CONCILIATED': 'Conciliated',
      'TYPE': 'Type',
      'ALL': 'All',
      'CREDIT': 'Credit',
      'DEBIT': 'Debit',
      'DOCUMENT': 'Document',
      'CHECK': 'Check',
      'TRANSFER': 'Transfer',
      'ACCOUNT_BALANCE': 'Account balance: ',
      'ACCOUNTS': 'Bank accounts',
      'EMPTY': 'There is no transactions to show',
      'EMPTY_ACCOUNT': 'You do not have bank accounts registered in this complex, you can click',
      'BANK_NAME': 'Bank: ',
      'BANK_INFO': 'Account name: ',
      'CONCILIATED_STATUS': 'Conciliated status: ',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Bad parameters',
      'DETAILS': 'View register',
      'SEE': 'View details',
      'CONCILIATE': 'Conciliate',
      'CREATE_CONCILIATE_ALERT': 'Are you sure to start the conciliation process?',
      'CONCILIATE_SUCCESS': 'Welcome to the conciliation process',
      'CLICK': 'Go to',
    },
    'MONTH': {
      'JAN': 'January',
      'FEB': 'February',
      'MAR': 'March',
      'APR': 'April',
      'MAY': 'May',
      'JUN': 'June',
      'JUL': 'July',
      'AUG': 'August',
      'SEP': 'September',
      'OCT': 'October',
      'NOV': 'November',
      'DEC': 'December',
    },
    'VALIDATIONS': {
      'BANK_REQUIRED': 'Bank account is required',
    },
    STATUS: {
      PENDING: 'Pending',
      CONCILIATED: 'Conciliated',
      CONCILIATED_WITH_CONFLICTS: 'Conciliated with conflict',
      PAID: 'Paid',
      REJECTED: 'Rejected',
    },
  }
};
