<div fxFlex.gt-sm="100%" fxFlex.lt-md="100%">
  <span class="h1">{{ 'PAY.PAY_CHARGE'|translate }} #{{ data.transactionId }}</span>
  <mat-divider></mat-divider><br>
  <span><strong>{{ 'PAY.ACCOUNT'|translate }}: #</strong>{{ detailTransaction.paymentId }}</span><br><br>
  <span><strong>{{ 'PAY.NAME_CATEGORY'|translate }}: </strong>{{ detailTransaction.category }}</span><br><br>
  <span><strong>{{ 'PAY.DESCRIPTION'|translate }}: </strong>{{ detailTransaction.description }}</span><br><br>
  <span><strong>{{ 'PAY.AMOUNT'|translate }}: {{ detailTransaction.amount }}</strong></span><br><br>
  <span><strong>{{ 'PAY.PAYMENT_TYPE'|translate }}: </strong>{{ detailTransaction.paymentType }}</span><br><br>
  <span><strong>{{ 'PAY.DOCUMENT_NAME'|translate }}: </strong>{{ detailTransaction.documentName }}</span><br><br>
  <span><strong>{{ 'PAY.DOCUMENT_NUMBER'|translate }}: </strong>{{ detailTransaction.documentNumber }}</span><br><br>
  <span><strong>{{ 'PAY.DATE'|translate }}: </strong>{{ detailTransaction.documentDate }}</span><br><br>
  <span><strong>{{ 'PAY.DOCUMENT_DESCRIPTION'|translate }}: </strong>{{ detailTransaction.documentDescription }}</span><br><br>
  <div>
    <button mat-raised-button
            class="ml-4" color="accent"
            (click)='closePayment(false);'>
      {{ 'PAY.CANCEL'|translate }}
    </button>
  </div>
</div>
