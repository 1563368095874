// Angular
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseConfirmDialogComponent } from '../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Material
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// Local services
import { ConciliationService } from '../../../conciliation.service';
import { ToastService } from '../../../../../../@fuse/utils/toast.service';
// Constants
import { Constants } from '../../../../../../@fuse/utils/constants';

@Component({
  selector: 'table-conciliation-trans',
  templateUrl: './table-conciliation-trans.component.html',
  styleUrls: ['./table-conciliation-trans.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class TableConciliationTransComponent implements OnInit, AfterViewInit {
  // data binding
  conciliatedId = '';
  conflict = Boolean;
  data = [];
  dataSource = new MatTableDataSource();
  displayedColumns = [
    'id',
    'status',
    'type',
    'transactionDate',
    'transactionNumber',
    'transactionAmount',
    'statementTransactionDate',
    'statementTransactionNumber',
    'statementTransactionAmount',
    'isDepositVoucher',
    'depositVoucherNumber',
    'actions',
  ];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  // Sort
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private activatedRoute: ActivatedRoute,
    private conciliationServices: ConciliationService,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private matDialog: MatDialog,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.conciliatedId = this.activatedRoute.snapshot.paramMap.get('conciliatedId');
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.getConciliationTransactions();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  getConciliationTransactions(): void {
    this.loader.start();
    this.conciliationServices.getConciliationTransaction({
        conciliationId: this.conciliatedId,
      }).subscribe(
      result => {
        if (result.success === true) {
          this.data = result.data;
          this.dataSource = new MatTableDataSource(result.data);
          this.dataSource.sort = this.sort;
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  conciliationTransaction(id: any, transaction: any): void {
    this.confirmDialogRef = this.matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    const conflict = transaction === null;
    this.confirmDialogRef.componentInstance.confirmMessage =
      this.translate.instant('MESSAGES.CONCILIATION_TRANSACTION_ALERT');
    this.confirmDialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.loader.start();
        this.conciliationServices.conciliateTransaction({
          transactionId: id,
          isConflict: conflict,
        }).subscribe(
          result => {
            if (result.success === true) {
              this.toast.showToast(
                Constants.TOAST_TYPE_SUCCESS,
                this.translate.instant('MESSAGES.CONCILIATION_TRANSACTION_SUCCESS'),
              );
              this.loader.stop();
              this.getConciliationTransactions();
            } else {
              this.toast.showToast(
                Constants.TOAST_TYPE_ERROR,
                this.translate.instant('MESSAGES.' + result.message),
              );
              this.loader.stop();
            }
          }, () => {
            this.router.navigate(['/pages/error/']);
            this.loader.stop();
          },
        );
      }
    });
  }

}
