export const locale = {
  lang: 'en',
  data: {
    'TABLE': {
      'NAME': 'Name',
      'DESCRIPTION': 'Description',
      'TYPE': 'Type account',
      'CREATED_BY': 'Created by',
      'ACTIONS': 'Actions',
    },
    'MESSAGES': {
      'DETAIL': 'Details',
    },
    TYPE_ACCOUNT: {
      INCOME: 'Income',
      EXPENSE: 'Expense',
    },
  }
};
