export const locale = {
  lang: 'es',
  data: {
    'ADD': {
      'APARTMENT_AMENITIES': 'Ambientes de propiedad',
      'FEE_NEW': 'Pago total: ',
      'PRICE': 'Precio por mt2: ',
      'SAVE': 'Guardar',
      'CANCEL': 'Cancelar',
      'TITLE': 'Configuración de distribución de espacio',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Parámetros erroneos.',
      'CONFIGURATION_SUCCESS_UPDATE': 'Configuración actualizada exitosamente.',
    },
  }
};
