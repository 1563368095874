<div class="p-12">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 table-accounting" fxHide.lt-md>
    <ng-container matColumnDef="alias">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.NAME'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.alias|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.DESCRIPTION'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.description|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.TYPE'|translate }} </th>
      <td mat-cell *matCellDef="let row">
        <mat-chip-list>
          <mat-chip class="chip-success" *ngIf="row.type === 'INCOME'">
            {{ 'TYPE_ACCOUNT.'+row.type|translate }}
          </mat-chip>
          <mat-chip class="chip-error" *ngIf="row.type !== 'INCOME'">
            {{ 'TYPE_ACCOUNT.'+row.type|translate }}
          </mat-chip>
        </mat-chip-list>
      </td>
    </ng-container>
    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.CREATED_BY'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.createdBy|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TABLE.ACTIONS'|translate }} </th>
      <td mat-cell *matCellDef="let row">
        <button mat-raised-button
                color="primary"
                class="ml-4"
                matTooltip="{{ 'MESSAGES.DETAIL'|translate }}"
                [routerLink]="['/catalogue/accounting-account/main/detail-category', this.complexId, row.id]">
          <mat-icon>settings</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="column" fxFlexFill fxHide.gt-sm>
    <mat-card class="w-100-p mt-8 mb-8" *ngFor="let categories of data">
      <div fxLayout="column" fxLayoutAlign="center space-between">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.NAME'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ categories.alias|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.DESCRIPTION'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ categories.description|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.TYPE'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            <mat-chip-list>
              <mat-chip class="chip-success" *ngIf="categories.type === 'INCOME'">
                {{ 'TYPE_ACCOUNT.'+categories.type|translate }}
              </mat-chip>
              <mat-chip class="chip-error" *ngIf="categories.type !== 'INCOME'">
                {{ 'TYPE_ACCOUNT.'+categories.type|translate }}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.CREATED_BY'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ categories.createdBy|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center" class="mt-16">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.ACTIONS'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            <button mat-raised-button color="primary" class="ml-4"
                    matTooltip="{{ 'MESSAGES.DETAIL'|translate }}"
                    [routerLink]="['/catalogue/accounting-account/main/detail-category', this.complexId, categories.id]">
              <mat-icon>settings</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <mat-paginator
    [length]="totalItems"
    [pageSize]="itemsPerPage"
    (page)="changePage($event)"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons>
  </mat-paginator>
</div>
