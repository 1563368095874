<div class="p-12" [fxHide]="data.length === 0">
  <div class="p-12"
       fxLayout.gt-sm="row"
       fxLayout.lt-md="column"
       fxLayoutAlign.gt-sm="end"
       fxLayoutAlign.lt-md="center">
    <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlexFill>
      <div fxFlex.gt-sm="50%" fxFlex.lt-md="100%">

      </div>
      <div fxFlex.gt-sm="50%" fxFlex.lt-md="100%" fxLayoutAlign.gt-sm="end end" fxLayoutAlign.lt-md="center center">
        <span><mat-icon class="icon-status-green ml-12">error</mat-icon> {{ 'TABLE.YES'|translate }}</span>
        <span><mat-icon class="icon-status-red ml-12">error</mat-icon> {{ 'TABLE.NOT'|translate }}</span>
      </div>
    </div>
  </div>
  <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" fxHide.lt-md>
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.ID'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.id|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.STATUS'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ 'STATUS.'+row.status| translate }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.TYPE'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ 'TYPE.'+row.type| translate }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="transactionDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.TRANSACTION_DATE'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.transactionDate|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="transactionNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.TRANSACTION_NUMBER'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.transactionNumber|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="transactionAmount">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.TRANSACTION_AMOUNT'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.transactionAmount|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="statementTransactionDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.TRANSACTION_DATE2'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.statementTransactionDate|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="statementTransactionNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.TRANSACTION_NUMBER2'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.statementTransactionNumber|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="statementTransactionAmount">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.TRANSACTION_AMOUNT2'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.statementTransactionAmount|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="isDepositVoucher">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.DEPOSIT'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-icon class="icon-status-green ml-12" *ngIf="row.isDepositVoucher === true">error</mat-icon>
        <mat-icon class="icon-status-red ml-12" *ngIf="row.isDepositVoucher === false">error</mat-icon>
      </mat-cell>
      <mat-cell *matCellDef="let row">
        <mat-icon class="icon-status-green ml-12" *ngIf="row.isDepositVoucher === true">error</mat-icon>
        <mat-icon class="icon-status-red ml-12" *ngIf="row.isDepositVoucher === false">error</mat-icon>
        {{ row.isDepositVoucher|ReplaceText }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="depositVoucherNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.DEPOSIT_NUMBER'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.depositVoucherNumber|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TABLE.ACTIONS'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-raised-button [disabled]="row.status !== 'PENDING'"
                [ngClass]="{'buttonSee': row.status === 'PENDING'}"
                matTooltip="{{ 'MESSAGES.CONCILIATED'|translate }}"
                (click)="conciliationTransaction(row.id, row.statementTransactionNumber);">
          <mat-icon>check_circle</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <div class="column" fxFlexFill fxHide.gt-sm>
    <mat-card class="w-100-p mt-8 mb-8" *ngFor="let conciliation of data">
      <div fxLayout="column" fxLayoutAlign="center space-between">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.ID'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliation.id|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.STATUS'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ 'STATUS.'+conciliation.status|translate }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.TYPE'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ 'TYPE.'+conciliation.type|translate }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.TRANSACTION_DATE'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliation.transactionDate|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.TRANSACTION_NUMBER'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliation.transactionNumber|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.TRANSACTION_AMOUNT'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliation.transactionAmount|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.TRANSACTION_DATE2'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliation.statementTransactionDate|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.TRANSACTION_NUMBER2'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliation.statementTransactionNumber|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.TRANSACTION_AMOUNT2'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliation.statementTransactionAmount|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.DEPOSIT'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            <mat-icon class="icon-status-green ml-12" *ngIf="conciliation.isDepositVoucher === true">error</mat-icon>
            <mat-icon class="icon-status-red ml-12" *ngIf="conciliation.isDepositVoucher === false">error</mat-icon>
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.DEPOSIT_NUMBER'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliation.depositVoucherNumber|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center" class="mt-16">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.ACTIONS'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            <button mat-raised-button [disabled]="conciliation.status !== 'PENDING'"
                    [ngClass]="{'buttonSee': conciliation.status === 'PENDING'}"
                    matTooltip="{{ 'MESSAGES.CONCILIATED'|translate }}"
                    (click)="conciliationTransaction(conciliation.id, conciliation.statementTransactionNumber);">
              <mat-icon>check_circle</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
<div class="mat-display-2 mt-8" align="center" *ngIf="data.length === 0">
  {{ 'MESSAGES.EMPTY_DATA'|translate }}
</div>
