export const locale = {
  lang: 'es',
  data: {
    'TABLE': {
      'NO': 'No. Transacción',
      'NO_DOCUMENT': 'Nombre Documento',
      'CREATED_BY': 'Creado por',
      'DATE': 'Fecha',
      'DESCRIPTION': 'Descripción',
      'TYPE_DOCUMENT': 'Estado',
      'ISSUING': 'Emisor',
      'EXPENSE': 'Gasto',
      'DEPOSIT': 'Depósito',
      'NAME': 'Nombre',
      'ACTIONS': 'Acciones',
      'MONTH': 'Mes',
      'YEAR': 'Año',
      'STATUS': 'Estado',
      'PAID': 'Pagado',
      'REJECTED': 'Rechazado',
      'PENDING': 'Pendiente',
      'CONCILIATED': 'Conciliado',
      'TYPE': 'Tipo',
      'ALL': 'Todos',
      'CREDIT': 'Crédito',
      'DEBIT': 'Débito',
      'DOCUMENT': 'Documento',
      'CHECK': 'Cheque',
      'TRANSFER': 'Transferencia',
      'ACCOUNT_BALANCE': 'Saldo de cuenta: ',
      'ACCOUNTS': 'Cuentas de banco',
      'EMPTY': 'No existe transacciones para mostrar',
      'EMPTY_ACCOUNT': 'No tiene cuentas de banco registradas en este complejo, puede agregar haciendo click',
      'BANK_NAME': 'Banco: ',
      'BANK_INFO': 'Nombre de cuenta: ',
      'CONCILIATED_STATUS': 'Estado de conciliación: ',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Parámetros erroneos',
      'DETAILS': 'Ver registro',
      'SEE': 'Ver detalles',
      'CONCILIATE': 'Conciliar',
      'CREATE_CONCILIATE_ALERT': '¿Esta seguro de iniciar el proceso de conciliación?',
      'CONCILIATE_SUCCESS': 'Bienvenido al proceso de conciliación',
      'CLICK': 'Aquí',
    },
    'MONTH': {
      'JAN': 'Enero',
      'FEB': 'Febrero',
      'MAR': 'Marzo',
      'APR': 'Abril',
      'MAY': 'Mayo',
      'JUN': 'Junio',
      'JUL': 'Julio',
      'AUG': 'Agosto',
      'SEP': 'Septiembre',
      'OCT': 'Octubre',
      'NOV': 'Noviembre',
      'DEC': 'Diciembre',
    },
    'VALIDATIONS': {
      'BANK_REQUIRED': 'Cuenta de banco es requerido',
    },
    STATUS: {
      PENDING: 'Pendiente',
      CONCILIATED: 'Conciliado',
      CONCILIATED_WITH_CONFLICTS: 'Conciliado con conflicto',
      PAID: 'Pagado',
      REJECTED: 'Rechazado',
    },
  }
};
