export const locale = {
  lang: 'es',
  data: {
    'PAY': {
      'ACCOUNT': 'Cuenta',
      'PAY_CHARGE': 'Transacción',
      'PAYMENT_TYPE': 'Forma de pago',
      'BANK_ACCOUNT': 'Cuenta de banco',
      'DOCUMENT_NUMBER': 'Número de documento',
      'DATE': 'Fecha de documento',
      'DOCUMENT_NAME': 'Nombre de documento',
      'NAME_CATEGORY': 'Nombre de categoría',
      'DESCRIPTION': 'Descripcion de categoria',
      'DOCUMENT_DESCRIPTION': 'Descripción de documento',
      'NOTES': 'Notas',
      'AMOUNT': 'Monto',
      'DECLINE_PAY': 'Declinar pago',
      'CANCEL': 'Cancelar',
    },
    'MESSAGES': {
      'PAY_SUCCESS': 'Pago declinado exitosamente',
    },
  }
};
