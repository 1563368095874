export const locale = {
  lang: 'es',
  data: {
    'ADD': {
      'NEW_EMPLOYEE': 'Agregar nuevo empleado',
      'UPDATE_EMPLOYEE': 'Actualizar empleado',
      'NAME': '*Nombres',
      'SURNAME': '*Apellidos',
      'DPI': '*DPI',
      'JOB': '*Puesto',
      'PHONE': '*Teléfono principal',
      'EMAIL': 'Correo electrónico',
      'SALARY': '*Salario base',
      'BOND': 'Bonos',
      'DISCOUNT': 'Descuentos',
      'TYPE_BOND': 'Bono',
      'TAX': 'NIT',
      'COUNTRY_CODE': '*Código',
      'SAVE': 'Guardar',
      'CANCEL': 'Cancelar',
      'UPDATE': 'Actualizar',
    },
    'VALIDATIONS': {
      'NAME_REQUIRED': 'Nombre es requerido',
      'SURNAME_REQUIRED': 'Apellido es requerido',
      'DPI_REQUIRED': 'DPI es requerido',
      'PHONE_REQUIRED': 'Teléfono es requerido',
      'EMAIL_REQUIRED': 'Correo electrónico es requerido',
      'SALARY_REQUIRED': 'Salario base es requerido',
      'JOB_REQUIRED': 'Puesto es requerido',
      'TAX_REQUIRED': 'NIT es requerido',
      'BAD_EMAIL_FORMAT': 'Formato de correo invalido.',
      'COUNTRY_CODE_REQUIRED': 'Código de país es requerido',
      'NUMBER_REQUIRED': 'Solo números son permitidos',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Parámetros erroneos.',
      'EMPLOYEE_ALREADY_EXIST': 'El empleado ya existe.',
      'EMPLOYEE_SUCCESS': 'Empleado agregado exitosamente.',
      'EMPLOYEE_SUCCESS_UPDATE': 'Empleado actualizado exitosamente.',
    },
  }
};
