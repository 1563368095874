// Angular
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
// Fuse
import {FuseSharedModule} from '@fuse/shared.module';
// Component local
import {ContentComponent} from 'app/layout/components/content/content.component';

@NgModule({
  declarations: [
    ContentComponent,
  ],
  imports: [
    RouterModule,
    FuseSharedModule,
  ],
  exports: [
    ContentComponent,
  ]
})
export class ContentModule {
}
