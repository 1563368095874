export const locale = {
  lang: 'es',
  data: {
    'LOGIN': {
      'EMAIL': 'Correo electrónico',
      'PASSWORD': 'Contraseña',
      'FORGOT_PASSWORD': '¿Olvidó su contraseña?',
      'LOGIN': 'Ingresar',
      'ENTER_ACCOUNT': 'INGRESAR A SU CUENTA',
      'TITLE': 'BIENVENIDOS A FINANCE',
      'DESCRIPTION': 'Bienvenido a la plataforma de administracion financiera de Beco, debe tener permisos de administrador o encargado de finanzas para poder ingresar.',
    },
    'VALIDATIONS': {
      'EMAIL_REQUIRED': 'Correo electrónico es requerido',
      'PASSWORD_REQUIRED': 'Contraseña es requerida',
      'BAD_EMAIL_FORMAT': 'Ingrese un correo electrónico válido',
    },
    'MESSAGES': {
      'BAD_CREDENTIALS': 'Su correo electrónico o contraseña son incorrectos',
      'USER_DONT_CHANNEL': 'Este usuario no pertenece a un canal',
      'USER_DONT_EXIST': 'El usuario no existe',
      'USER_NOT_AUTHENTICATED': 'Usuario no autenticado',
    },
  },
};
