<span class="h1" *ngIf="data.id == null">{{ 'ADD_ACCOUNT.CREATE_ACCOUNT'|translate }}</span>
<span class="h1"  *ngIf="data.id != null">{{ 'ADD_ACCOUNT.UPDATE_ACCOUNT'|translate }}</span>
<mat-divider></mat-divider>
<div fxLayout="column" class="mt-12">
  <form [formGroup]="addForm" class="mt-12" name="payForm" novalidate>
    <mat-form-field appearance="outline" class="w-100-p">
      <mat-label>{{ 'ADD_ACCOUNT.NAME'|translate }}</mat-label>
      <input formControlName="name" matInput>
      <mat-error>
        {{ 'VALIDATIONS.NAME_REQUIRED'|translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100-p">
      <mat-label>{{ 'ADD_ACCOUNT.DESCRIPTION'|translate }}</mat-label>
      <input formControlName="description" matInput>
      <mat-error>
        {{ 'VALIDATIONS.DESCRIPTION_REQUIRED'|translate }}
      </mat-error>
    </mat-form-field>
    <button *ngIf="data.id == null" (click)='newAccount(true);'  [disabled]="addForm.invalid" color="primary" mat-raised-button>
      {{ 'ADD_ACCOUNT.SAVE'|translate }}
    </button>
    <button *ngIf="data.id != null" (click)='updateAccount(true);'  [disabled]="addForm.invalid" color="primary" mat-raised-button>
      {{ 'ADD_ACCOUNT.UPDATE'|translate }}
    </button>
    <button (click)="newAccount(false)" class="ml-4" color="accent" mat-raised-button>
      {{ 'ADD_ACCOUNT.CANCEL'|translate }}
    </button>
  </form>
</div>
