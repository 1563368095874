// Angular
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Constants
import { Constants } from '@fuse/utils/constants';
// Toast
import { DistributionService } from '../../distribution.service';
import { ToastService } from '@fuse/utils/toast.service';

@Component({
  selector: 'distribution-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class AddComponent implements OnInit {
// Reactive form
  addForm: FormGroup;
  // Data bindings
  complexId = '';
  propertyId = '';
  distributions = [];
  distributionConfig = false;
  currencyIso = {
    iso: '',
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private distributionService: DistributionService,
    private formBuilder: FormBuilder,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.complexId = this.activatedRoute.snapshot.paramMap.get('complexId');
    this.propertyId = this.activatedRoute.snapshot.paramMap.get('propertyId');
    this.addForm = this.formBuilder.group({
    });
    this.getVerify();
    this.getDistributionInfo();
  }

  getVerify(): void {
    this.loader.start();
    this.distributionService.getDistributionVerify(
      this.complexId,
    ).subscribe(
      result => {
        if (result.success === true && result.data) {
          this.distributionConfig = result.data.distribution;
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  getDistributionInfo(): void {
    this.loader.start();
    this.distributionService.getDistribution(
      this.propertyId, {
        propertyId: this.propertyId,
      }).subscribe(
      result => {
        if (result.success === true && result.data) {
          this.distributions = result.data.data;
          this.currencyIso.iso = result.data.currency.iso;
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      },
      () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  newDistribution(): void {
    this.loader.start();
    this.distributionService.addDistribution(
      this.propertyId, {
        data: this.distributions,
      }).subscribe(
      result => {
        if (result.success === true) {
          this.toast.showToast(
            Constants.TOAST_TYPE_SUCCESS,
            this.translate.instant('MESSAGES.CONFIGURATION_SUCCESS_UPDATE'),
          );
          this.router.navigate(['/distribution/main']);
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, error => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      },
    );
  }

}
