<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
  <div id="login-intro" fxFlex fxHide fxShow.gt-xs>
    <div fxLayout="column" fxLayoutAlign="space-between start" fxFlexFill>
      <div fxFlex>
        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
          <img src="assets/images/logos/beco-invert.png">
        </div>
        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
          <span class="mat-display-1">{{ 'LOGIN.TITLE'|translate }}</span>
        </div>
        <div class="description">{{ 'LOGIN.DESCRIPTION'|translate }}</div>
        <div class="mt-20">
          <div fxLayout="row" fxLayoutAlign="start center">
            <a *ngFor="let lang of languages" (click)="setLanguage(lang)" class="link-lang">
              <span fxLayout="row" fxLayoutAlign="start center">
                <img class="flag-auth mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                <span class="iso mr-16">{{ lang.title }}</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-image">
      <img src="assets/images/logos/bac-white.png" alt="">
    </div>
  </div>
  <div id="login-form-wrapper" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
    <div id="login-form">
      <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}" fxHide.gt-xs>
        <img src="assets/images/logos/beco.png" alt="assets/images/logos/beco.png">
      </div>
      <div class="title"><span class="h1">{{ 'LOGIN.ENTER_ACCOUNT'|translate }}</span></div>
      <form name="loginForm" [formGroup]="loginForm" novalidate>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'LOGIN.EMAIL'|translate }}</mat-label>
          <input matInput formControlName="email">
          <mat-icon matSuffix class="secondary-text">mail</mat-icon>
          <mat-error *ngIf="loginForm.get('email').hasError('required')">
            {{ 'VALIDATIONS.EMAIL_REQUIRED'|translate }}
          </mat-error>
          <mat-error *ngIf="!loginForm.get('email').hasError('required') &&
                  loginForm.get('email').hasError('email')">
            {{ 'VALIDATIONS.BAD_EMAIL_FORMAT'|translate }}
          </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
          <mat-label>{{ 'LOGIN.PASSWORD'|translate }}</mat-label>
          <input matInput type="password" formControlName="password">
          <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
          <mat-error>
            {{ 'VALIDATIONS.PASSWORD_REQUIRED'|translate }}
          </mat-error>
        </mat-form-field>
        <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
          <a class="forgot-password" [routerLink]="'/oauth/forgot-password'">
            {{ 'LOGIN.FORGOT_PASSWORD'|translate }}
          </a>
        </div>
        <button mat-raised-button color="accent" class="submit-button" [disabled]="loginForm.invalid" (click)="login();">
          {{ 'LOGIN.LOGIN'|translate }}
        </button>
      </form><br>
      <div class="mt-12" fxHide.gt-sm>
        <div fxLayout="row" fxLayoutAlign="center center">
          <a *ngFor="let lang of languages" (click)="setLanguage(lang)" class="link-lang2">
              <span fxLayout="row" fxLayoutAlign="start center">
                <img class="flag-auth mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                <span class="iso mr-16">{{ lang.title }}</span>
              </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
