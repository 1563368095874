export const locale = {
  lang: 'en',
  data: {
    'TABLE': {
      'ID': 'No.',
      'STATUS': 'Status',
      'TYPE': 'Type',
      'TRANSACTION_DATE': 'Transaction date (BECO)',
      'TRANSACTION_NUMBER': 'Transaction number (BECO)',
      'TRANSACTION_AMOUNT': 'Transaction amount (BECO)',
      'TRANSACTION_DATE2': 'Transaction date (Account status)',
      'TRANSACTION_NUMBER2': 'Transaction number (Account status)',
      'TRANSACTION_AMOUNT2': 'Transaction amount (Account status)',
      'DEPOSIT': 'Deposit voucher',
      'DEPOSIT_NUMBER': 'No. Deposit',
      'ACTIONS': 'Actions',
      'NOT': 'No',
      'YES': 'Yes',
    },
    'MESSAGES': {
      'CONCILIATED': 'Conciliated',
      'REMOVE': 'Delete',
      'REMOVE_JOB_SUCCESS': 'Job successfully removed',
      'REMOVE_JOB_ALERT': 'Are you sure to remove job?',
      'CONCILIATION_TRANSACTION_ALERT': 'Are you sure you can conciliation this transaction?',
      'CONCILIATION_TRANSACTION_SUCCESS': 'Conciliation successful',
      'EMPTY_DATA': 'There are no registers to show',
    },
    STATUS: {
      PENDING: 'Pending',
      CONFLICT: 'Conflict',
      CONCILIATED: 'Conciliated',
    },
    TYPE: {
      INCOME: 'Income',
      EXPENSE: 'Expense',
    },
  }
};
