// Angular Component
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Local Services
import { OauthService } from '../oauth.service';
import { ToastService } from '../../../../@fuse/utils/toast.service';
import * as _ from 'lodash';
// Fuse
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
// Constants
import { Constants } from '../../../../@fuse/utils/constants';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ForgotPasswordComponent implements OnInit {
  // Reactive form
  forgotPasswordForm: FormGroup;

  selectedLanguage: any;
  languages: any;

  constructor(
    private authService: OauthService,
    private formBuilder: FormBuilder,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
    private translateService: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
    // Configure the layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      }
    };

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'es',
        title: 'Español',
        flag: 'es'
      }
    ];
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.selectedLanguage = _.find(this.languages, { id: this.translateService.currentLang });
  }

  forgotPassword(): void {
    this.loader.start();
    this.authService.forgotPassword({
      email: this.forgotPasswordForm.controls.email.value,
      lang: this.translate.getDefaultLang(),
    }).subscribe(
      result => {
        if (result.success === true) {
          this.toast.showToast(
            Constants.TOAST_TYPE_SUCCESS,
            this.translate.instant('MESSAGES.CHECK_EMAIL'),
          );
          this.router.navigate(['/oauth/login']);
          this.loader.stop();
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, error => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      },
    );
  }

  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this.translateService.use(lang.id);

    localStorage.setItem('lang', lang.id);
  }
}
