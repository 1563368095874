// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
// Material
import { MatDialogRef } from '@angular/material/dialog';
// Fuse
import { environment } from '../../../../../../environments/environment';
import { fuseAnimations } from '../../../../../../@fuse/animations';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Toast
import { ToastService } from '../../../../../../@fuse/utils/toast.service';
// Local Service
import { ReceivableAccountsService } from '../../../receivable-accounts.service';

@Component({
  selector: 'details-pay',
  templateUrl: './details-pay.component.html',
  styleUrls: ['./details-pay.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class DetailsPayComponent implements OnInit {
  // Date
  public data: any;
  detailTransaction: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private receivableAccountServices: ReceivableAccountsService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<DetailsPayComponent>,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.transactionDetail();
  }

  transactionDetail(): void{
    this.loader.start();
    this.receivableAccountServices.getTransactionDetail({
      transactionId: this.data.transactionId,
      lang: this.translate.currentLang,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.detailTransaction = result.data;
          this.loader.stop();
        }
      }, error => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      }
    );
  }

  closePayment(confirm: any): void {
    const data = {
      confirm,
      lang: this.translate.currentLang,
    };
    this.dialogRef.close(data);
  }

}
