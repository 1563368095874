<div id="forgot-password" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
  <div id="forgot-password-intro" fxFlex fxHide fxShow.gt-xs>
    <div fxLayout="column" fxLayoutAlign="space-between start" fxFlexFill>
      <div fxFlex>
        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
          <img src="assets/images/logos/beco-invert.png">
        </div>
        <div class="title"[@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
          <span class="mat-display-1"> {{ 'FORGOT_PASSWORD.TITLE' | translate }} </span>
        </div>
        <div class="description">{{ 'FORGOT_PASSWORD.DESCRIPTION'|translate }}</div>
        <div class="mt-20">
          <div fxLayout="row" fxLayoutAlign="start center">
            <a *ngFor="let lang of languages" (click)="setLanguage(lang)" class="link-lang">
              <span fxLayout="row" fxLayoutAlign="start center">
                <img class="flag-auth mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                <span class="iso mr-16">{{ lang.title }}</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-image">
      <img src="assets/images/logos/bac-white.png">
    </div>
  </div>
  <div id="forgot-password-form-wrapper" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
    <div id="forgot-password-form">
      <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}" fxHide.gt-xs>
        <img src="assets/images/logos/beco.png">
      </div>
      <div class="title">{{ 'FORGOT_PASSWORD.RECOVER_PASSWORD'|translate }}</div>
      <form name="forgoPasswordForm" [formGroup]="forgotPasswordForm" novalidate>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'FORGOT_PASSWORD.EMAIL'| translate }}</mat-label>
          <input matInput formControlName="email">
          <mat-icon matSuffix class="secondary-text">mail</mat-icon>
          <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
            {{ 'VALIDATIONS.EMAIL_REQUIRED'|translate }}
          </mat-error>
          <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
            {{ 'VALIDATIONS.BAD_EMAIL_FORMAT'|translate }}
          </mat-error>
        </mat-form-field>
        <button mat-raised-button class="submit-button" color="accent" [disabled]="forgotPasswordForm.invalid" (click)='forgotPassword();'>
          {{ 'FORGOT_PASSWORD.SEND_RESET'| translate }}
        </button>
      </form>
      <div class="login" fxLayout="row" fxLayoutAlign="center center">
        <a class="link" [routerLink]="'/pages/auth/login'">{{ 'FORGOT_PASSWORD.BACK_LOGIN'|translate }}</a>
      </div>
    </div>
  </div>
</div>

