// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Local components
import { Global } from '@fuse/utils/global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DistributionService {

  constructor(
    private http: HttpClient,
  ) { }

  public getListDistribution(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}distribution`,
      httpOptions,
    );
  }

  public getDistribution(propertyId: any, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}distribution/${propertyId}`,
      httpOptions,
    );
  }

  public getDistributionVerify(complexId: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.get(
      `${Global.BASE_API_URL}distribution/verify/${complexId}`,
      httpOptions,
    );
  }

  public addDistribution(propertyId: any, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.put(
      `${Global.BASE_API_URL}distribution/${propertyId}`,
      data,
      httpOptions,
    );
  }

}
