export const locale = {
  lang: 'es',
  data: {
    'DETAIL_TRANSACTION': {
      'ACCOUNT': 'Cuenta',
      'TITLE': 'Transacción',
      'NOTES': 'Nota',
      'BANK_ACCOUNT': 'Cuenta de banco',
      'DOCUMENT_NUMBER': 'Número de documento',
      'DATE': 'Fecha de documento',
      'DOCUMENT_NAME': 'Nombre de documento',
      'NAME_CATEGORY': 'Nombre de categoría',
      'DESCRIPTION': 'Descripcion',
      'DOCUMENT_DESCRIPTION': 'Descripción de documento',
      'AMOUNT': 'Monto',
      'DECLINE_PAY': 'Declinar pago',
      'CANCEL': 'Cancelar',
    },
    'MESSAGES': {
      'PAY_SUCCESS': 'Pago declinado exitosamente',
    },
  }
};
