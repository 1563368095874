export const locale = {
  lang: 'en',
  data: {
    'TABLE': {
      'NO_PROPERTY': 'No. Property',
      'TENANT': 'Tenant',
      'SPACE': 'Space property (mt2)',
      'AMENITIES': 'Space amenities (mt2)',
      'TOTAL': 'Total (mt2)',
      'QUOTA': 'Quota',
      'ACTIONS': 'Actions',
      'STATUS': 'Status',
      'ACTIVE': 'Property available',
      'INACTIVE': 'Occupied property',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Bad parameters',
      'EDIT': 'Edit distribution ',
      'NOT_CONFIGURATION': 'This complex has no layout configurations. You must go to the Web Admin to configure your space by clicking',
      'CLICK': '  Go to',
    },
  }
};
