// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '../../../../@fuse/components/navigation/navigation.service';
import { environment } from '../../../../environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Constants
import { Constants } from '../../../../@fuse/utils/constants';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Local Services
import { OauthService } from '../oauth.service';
import { ToastService } from '../../../../@fuse/utils/toast.service';
import * as _ from 'lodash';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
  // Reactive form
  loginForm: FormGroup;
  selectedLanguage: any;
  languages: any;

  constructor(
    private formBuilder: FormBuilder,
    private fuseConfigService: FuseConfigService,
    private fuseNavigationService: FuseNavigationService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private oauthService: OauthService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
    private translateService: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
    // Configure the layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      }
    };

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'es',
        title: 'Español',
        flag: 'es'
      }
    ];
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.selectedLanguage = _.find(this.languages, { id: this.translateService.currentLang });
  }

  login(): void {
    this.loader.start();
    this.oauthService.login({
      email: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value,
    }).subscribe(
      result => {
        if (result.success === true) {
          localStorage.setItem('token', result.data.token);
          localStorage.setItem('name', result.data.name);
          localStorage.setItem('id', result.data.id);
          localStorage.setItem('role', result.data.role);
          localStorage.setItem('email', result.data.email);
          localStorage.setItem('logo', result.data.logo);
          this.setNavigation(result.data.role);
        } else {
          this.toast.showToast(
            Constants.TOAST_TYPE_ERROR,
            this.translate.instant('MESSAGES.' + result.message),
          );
          this.loader.stop();
        }
      }, error => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      },
    );
  }

  setNavigation(role): void {
    let nav = '';
    let home = '';
    switch (role) {
      case 'SUPER ADMIN': {
        nav = 'main';
        home = '/dashboard/main';
        break;
      }
      case 'ADMIN': {
        nav = 'second';
        home = '/dashboard/main';
        break;
      }
      case 'COMPLEX ADMIN': {
        nav = 'second';
        home = '/dashboard/main';
        break;
      }
      case 'FINANCE USER': {
        nav = 'finance';
        home = '/dashboard/main';
        break;
      }
      default: {
        nav = '';
      }
    }
    this.fuseNavigationService.setCurrentNavigation(nav);
    this.router.navigate([home]);
    this.loader.stop();
  }

  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this.translateService.use(lang.id);
    localStorage.setItem('lang', lang.id);
  }
}
