export const locale = {
  lang: 'es',
  data: {
    'ADD': {
      'TITLE1': 'Cargar archivo',
      'TITLE2': 'Verificar archivo',
      'NEXT': 'Siguiente',
      'BACK': 'Regresar',
      'FINISH': 'Finalizar',
      'FILE': 'Añada el archivo .CSV',
      'NO_DOCUMENT': 'No. de columna del No. de documento',
      'NO_CREDIT': 'No. de columna de haber (crédito)',
      'NO_DEBIT': 'No. de columna del debe (débito)',
      'NO_DATE': 'No. de columna de fecha',
      'SELECT': 'Seleccione la opción requerida',
    },
    'VALIDATIONS': {
      'NO_DOCUMENT_REQUIRED': 'No. de columna del documento es requerido',
      'NO_CREDIT_REQUIRED': 'No. de columna de crédito es requerido',
      'NO_DEBIT_REQUIRED': 'No. de columna de débito es requerido',
      'NO_DATE_REQUIRED': 'No. de columna de fecha es requerido',
      'NO_COLUMN': 'No. de columna es requerido',
      'INVALID_REQUIRED': 'Esta opción no es válida.',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Parametros erroneos',
      'FILE_COLUMNS_SUCCESS': 'Archivo subido correctamente.',
      'LOAD_FILE_SUCCESS': 'Archivo cargado con éxito.',
    },
    'INSTRUCTIONS': {
      'TEXT1': 'Instrucciones',
      'TEXT2': 'Para realizar el proceso de conciliación necesita modifcar el archivo que descarga de su banco.',
      'TEXT3': 'Para este proceso debe eliminar todas las filas anteriores al encabezado que le aparece en su documento.',
      'TEXT4': 'Debe eliminar las filas que le aparecen después de que termine la tabla de sus registros.',
      'TEXT5': 'Si usted hizo bien el proceso en el Paso 2 debe de mostrarle el nombre de los encabezados de la tabla de su archivo.',
      'TEXT6': 'Si esto no ocurre solicite ayuda a support@gameboard.tech adjuntadon su archivo.',
      'TEXT7': 'Si no sabe como hacerlo presione el boton "Ver tutorial"',
      'TEXT8': 'Columna 1 No. de Doc: puede identificar esta columna en su archivo porque en ella esta contenido los números de transacción, puede encontrarlos con otros nombres por lo tanto verifique en su archivo.',
      'TEXT9': 'Columna 2 de haber (credito): puede identificar esta columna en su archivo porque en ella esta contenido los montos de credito de su cuenta.',
      'TEXT10': 'Columna 3 de debe (debito): puede identificar esta columna en su archivo porque en ella esta contenido los montos de debito de su cuenta.',
      'TEXT11': 'Columna 4 de fecha: puede identificar esta columna en su archivo por que en ella esta contenida la fechas de sus transacciones.',
      'TEXT12': 'Si tiene alguna duda solicite ayuda a support@gameboard.tech adjuntando su archivo.',
      'TUTORIAL': 'Ver tutorial',
    },
  }
};
