export const locale = {
  lang: 'en',
  data: {
    'HEADER': {
      'NEW_EMPLOYEE': 'New employee',
      'SEARCH': 'Search employees',
      'TITLE': 'Employees list',
    },
  }
};
