// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Local components
import { Global } from '@fuse/utils/global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReceivableAccountsService {

  constructor(
    private http: HttpClient,
  ) { }

  public getListReceivableAccounts(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}receivable_account`,
      httpOptions,
    );
  }

  public getListBankAccounts(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}receivable_account/bank_account`,
      httpOptions,
    );
  }

  public getTransactionDetail(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}receivable_account/transaction/detail`,
      httpOptions,
    );
  }

  public getPaymentType(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}receivable_account/payment_type`,
      httpOptions,
    );
  }

  public getPendingPayments(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}receivable_account/payment/pending`,
      httpOptions,
    );
  }

  public getPaidPayments(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}receivable_account/payment/paid`,
      httpOptions,
    );
  }

  public getRejectPayments(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}receivable_account/payment/reject`,
      httpOptions,
    );
  }

  public addRegisterPayment(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.post(
      `${Global.BASE_API_URL}receivable_account/payment/register`,
      data,
      httpOptions,
    );
  }

  public declinePayment(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.put(
      `${Global.BASE_API_URL}receivable_account/payment/decline`,
      data,
      httpOptions,
    );
  }

  public conciliatePayments(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.put(
      `${Global.BASE_API_URL}receivable_account/payment/conciliate`,
      data,
      httpOptions,
    );
  }

  public getRates(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}charge/get_rates`,
      httpOptions,
    );
  }

  public getPaymentTypesCharge(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}charge/payment_types`,
      httpOptions,
    );
  }

  public getSectors(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}charge/sectors`,
      httpOptions,
    );
  }

  public getProperties(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      params: data,
    };
    return this.http.get(
      `${Global.BASE_API_URL}charge/properties`,
      httpOptions,
    );
  }

  public addNewCharge(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return this.http.post(
      `${Global.BASE_API_URL}charge`,
      data,
      httpOptions,
    );
  }
}
