<div fxLayout="row wrap" class="p-12" fxLayoutAlign="strech strech" *ngIf="banks.length !== 0">
  <mat-card class="w-100-p">
    <form name="complexSelectForm" [formGroup]="filtersForm" novalidate>
      <div fxLayout="row wrap" class="p-12" fxLayoutAlign="space-between center" fxFill>
        <div fxFlex.lt-md="100%" fxFlex.gt-sm="50%">
          <div fxLayoutAlign.lt-lg="start start" fxLayoutAlign.gt-md="start start"  fxLayout="column">
            <mat-form-field appearance="outline" class="w-100-p" *ngIf="banks.length !== 0">
              <mat-label>{{ 'TABLE.ACCOUNTS' |translate }}</mat-label>
              <mat-select formControlName="bankAccount" (selectionChange)="getConciliation($event.value);">
                <mat-option *ngFor="let bank of banks" [value]="bank">{{ bank.bank }}-{{ bank.accountNumber}}</mat-option>
              </mat-select>
              <mat-error>
                {{ 'VALIDATIONS.BANK_REQUIRED'|translate }}
              </mat-error>
            </mat-form-field>
            <span *ngIf="verifyConciliate.create === false" class="h3">
              <strong>{{ 'TABLE.CONCILIATED_STATUS'|translate }}
              </strong>{{ 'STATUS.'+verifyConciliate.status|translate }}
            </span>
            <button mat-raised-button class="primary mt-16"
                    *ngIf="verifyConciliate.create === true && banks.length !== 0 && data.length !== 0"
                    (click)='conciliateOperation();'
                    matTooltip="{{ 'MESSAGES.CONCILIATE'|translate }}">{{ 'MESSAGES.CONCILIATE'|translate }}
            </button>
            <button mat-raised-button color="primary" class="mt-16"
                    *ngIf="verifyConciliate.create === false && banks.length !== 0 && data.length !== 0"
                    [routerLink]="['/conciliation/main/detail', complexId, verifyConciliate.id, accountId]"
                    matTooltip="{{ 'MESSAGES.DETAILS'|translate }}">{{ 'MESSAGES.DETAILS'|translate }}
            </button>
          </div>
        </div>
        <div fxFlex.lt-md="100%" fxFlex.gt-sm="44%" class="mt-12">
          <div fxLayoutAlign.lt-lg="start start" fxLayoutAlign.gt-md="start start"  fxLayout="row">
          <mat-form-field  appearance="outline" class="w-50-p">
            <mat-label>{{ 'TABLE.YEAR' |translate }}</mat-label>
            <mat-select formControlName="year" (selectionChange)="loadFilters()">
              <mat-option *ngFor="let year of yearCatalog" value="{{ year.value }}">{{ year.value|translate }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field  appearance="outline" class="w-50-p ml-4">
            <mat-label>{{ 'TABLE.MONTH' |translate }}</mat-label>
            <mat-select formControlName="month" (selectionChange)="loadFilters()">
              <mat-option *ngFor="let month of monthCatalog" value="{{ month.value }}">{{ month.name|translate }}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <div fxLayoutAlign.lt-lg="start start" fxLayoutAlign.gt-md="start start"  fxLayout="row">
          <mat-form-field  appearance="outline" class="w-50-p">
            <mat-label>{{ 'TABLE.TYPE' |translate }}</mat-label>
            <mat-select formControlName="type" (selectionChange)="changeCurrentType();">
              <mat-option value="0">{{ 'TABLE.ALL' |translate }}</mat-option>
              <mat-option value="INCOME">{{ 'TABLE.CREDIT' |translate }}</mat-option>
              <mat-option value="EXPENSE">{{ 'TABLE.DEBIT' |translate }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field  appearance="outline" class="w-50-p ml-4">
            <mat-label>{{ 'TABLE.STATUS' |translate }}</mat-label>
            <mat-select formControlName="status" (selectionChange)="changeCurrentStatus();">
              <mat-option value="0">{{ 'TABLE.ALL' |translate }}</mat-option>
              <mat-option value="PAID">{{ 'TABLE.PAID' |translate }}</mat-option>
              <mat-option value="REJECTED">{{ 'TABLE.REJECTED' |translate }}</mat-option>
              <mat-option value="PENDING">{{ 'TABLE.PENDING' |translate }}</mat-option>
              <mat-option value="CONCILIATED">{{ 'TABLE.CONCILIATED' |translate }}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <span class="h1 ml-12 mt-8">{{ 'TABLE.ACCOUNT_BALANCE'|translate }}</span>
          <span class="h1" *ngIf="banks.length !== 0"><strong>{{ accountBalance.amount }}</strong></span>
        </div>
      </div>
    </form>
  </mat-card>
</div>
<div class="p-12">
  <div [fxHide]="data.length === 0 || banks.length === 0">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 mt-12 table-conciliation" fxHide.lt-md>
    <ng-container matColumnDef="transactionId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.NO'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.transactionId|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="documentName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.NO_DOCUMENT'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.documentName|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="nameEs">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.NAME'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.nameEs|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.CREATED_BY'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.createdBy|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.DATE'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.date|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.DESCRIPTION'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.description|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.TYPE_DOCUMENT'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ 'STATUS.'+row.status|translate }} </td>
    </ng-container>
    <ng-container matColumnDef="debit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.EXPENSE'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.debit|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="credit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.DEPOSIT'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.credit|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TABLE.ACTIONS'|translate }} </th>
      <td mat-cell *matCellDef="let row">
        <button mat-raised-button *ngIf="row.isComplex == '0'"
                color="primary" class="ml-4"
                matTooltip="{{ 'MESSAGES.SEE'|translate }}"
                (click)='detailsTransaction(row, row.transactionId);'>
          <mat-icon>visibility</mat-icon>
        </button>
        <button mat-raised-button *ngIf="row.isComplex == '1'"
                color="primary" class="ml-4"
                matTooltip="{{ 'MESSAGES.SEE'|translate }}"
                (click)='detailsTransactions(row, row.transactionId);'>
          <mat-icon>visibility</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="column mt-12" fxFlexFill fxHide.gt-sm>
    <mat-card class="w-100-p mt-8 mb-8" *ngFor="let conciliate of data">
      <div fxLayout="column" fxLayoutAlign="center space-between">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.NO'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliate.transactionId|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.NO_DOCUMENT'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliate.documentName|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.NAME'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliate.nameEs|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.CREATED_BY'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliate.createdBy|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.DATE'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliate.date|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.DESCRIPTION'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliate.description|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.TYPE_DOCUMENT'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ 'STATUS.'+conciliate.status|translate }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.EXPENSE'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliate.debit|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.DEPOSIT'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ conciliate.credit|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center" class="mt-16">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.ACTIONS'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            <button mat-raised-button color="primary" class="ml-4" *ngIf="conciliate.isComplex == '0'"
                    matTooltip="{{ 'MESSAGES.SEE'|translate }}"
                    (click)='detailsTransaction(conciliate, conciliate.transactionId);'>
              <mat-icon>visibility</mat-icon>
            </button>
            <button mat-raised-button color="primary" class="ml-4" *ngIf="conciliate.isComplex == '1'"
                    matTooltip="{{ 'MESSAGES.SEE'|translate }}"
                    (click)='detailsTransactions(conciliate, conciliate.transactionId);'>
              <mat-icon>visibility</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <mat-paginator
    [length]="totalItems"
    [pageSize]="itemsPerPage"
    (page)="changePage($event)"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons>
  </mat-paginator>
</div>
</div>
<div class="p-12">
  <mat-card class="config" *ngIf="banks.length === 0">
    <div fxLayoutAlign="strech center">
      <span class="h3"><strong>{{ 'TABLE.EMPTY_ACCOUNT'|translate }}</strong></span>
      <button mat-raised-button color="primary" class="ml-12"
              matTooltip="{{ 'MESSAGES.CLICK'|translate }}"
              [routerLink]="['/bank-account/main']">
        <mat-icon>build</mat-icon>
      </button>
    </div>
  </mat-card>
</div>
<div class="mat-display-2 mt-8" align="center" *ngIf="data.length === 0">
  {{ 'TABLE.EMPTY'|translate }}
</div>
