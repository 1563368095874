// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// Angular material
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
// Fuse
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
// Component local
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import {CommonComponentModule} from '../../../main/common-component/common-component.module';

@NgModule({
  declarations: [
    ToolbarComponent,
  ],
  imports: [
    RouterModule,
    // Material
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    // Fuse
    FuseSearchBarModule,
    FuseSharedModule,
    FuseShortcutsModule,
    CommonComponentModule,
  ],
  exports: [
    ToolbarComponent,
  ]
})
export class ToolbarModule {
}
