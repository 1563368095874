<div class="ml-12">
  <span class="h2 mt-12"><strong>{{ 'TABLE.TITLE'|translate }}</strong></span>
    <button mat-mini-fab color="primary" ngClass.gt-sm="ml-12" ngClass.lt-md="ml-12"
            matTooltip="{{ 'TABLE.ADD_ACCOUNT'|translate }}"
            (click)="newAccount(info);">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div class="p-12" [fxHide]="data.length === 0">
  <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 mt-12" fxHide.lt-md>
    <ng-container matColumnDef="alias">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.NAME'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.alias|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.DESCRIPTION'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.description|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="createdBy">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.CREATED_BY'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.createdBy|ReplaceText }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TABLE.ACTIONS'|translate }} </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-raised-button color="accent" class="ml-4"
                matTooltip="{{ 'MESSAGES.EDIT'|translate }}"
                (click)="updateAccount(row)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-raised-button color="warn"
                class="ml-4" matTooltip="{{ 'MESSAGES.REMOVE'|translate }}"
                (click)='deleteAccount(row.id)'>
          <mat-icon>delete_outline</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <div class="column" fxFlexFill fxHide.gt-sm>
    <mat-card class="w-100-p mt-8 mb-8" *ngFor="let accounts of data">
      <div fxLayout="column" fxLayoutAlign="center space-between">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.NAME'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ accounts.alias|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.DESCRIPTION'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ accounts.description|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.CREATED_BY'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ accounts.createdBy|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center" class="mt-16">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.ACTIONS'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            <button mat-raised-button
                    color="accent" class="ml-4"
                    matTooltip="{{ 'MESSAGES.EDIT'|translate }}"
                    (click)="updateAccount(accounts)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-raised-button color="warn"
                    class="ml-4" matTooltip="{{ 'MESSAGES.REMOVE'|translate }}"
                    (click)='deleteAccount(accounts.id)'>
              <mat-icon>delete_outline</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <mat-paginator
    [length]="totalItems"
    [pageSize]="itemsPerPage"
    (page)="changePage($event)"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons>
  </mat-paginator>
</div>
<div class="mat-display-2 mt-8" align="center" *ngIf="data.length === 0">
  {{ 'TABLE.EMPTY'|translate }}
</div>
