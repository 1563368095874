export const locale = {
  lang: 'en',
  data: {
    'ADD': {
      'TITLE1': 'Load file',
      'TITLE2': 'Verify file',
      'NEXT': 'Next',
      'BACK': 'Back',
      'FINISH': 'Finish',
      'FILE': 'Add file .CSV',
      'NO_DOCUMENT': 'Column No. of Document No.',
      'NO_CREDIT': 'Credit column number',
      'NO_DEBIT': 'Debit column number',
      'NO_DATE': 'Date column number',
      'SELECT': 'Select the required option',
    },
    'VALIDATIONS': {
      'NO_DOCUMENT_REQUIRED': 'Document column number is required',
      'NO_CREDIT_REQUIRED': 'Credit column number is required',
      'NO_DEBIT_REQUIRED': 'Debit column number is required',
      'NO_DATE_REQUIRED': 'Date column number is required',
      'NO_COLUMN': 'Column number is required',
      'INVALID_REQUIRED': 'This option is not valid',
    },
    'MESSAGES': {
      'BAD_PARAMETERS': 'Bad parameters',
      'FILE_COLUMNS_SUCCESS': 'File uploaded successfully.',
      'LOAD_FILE_SUCCESS': 'Load file success.',
    },
    'INSTRUCTIONS': {
      'TEXT1': 'Instructions',
      'TEXT2': 'To carry out the conciliation process, you need to modify the file that you download from your bank.',
      'TEXT3': 'For this process you must delete all the rows before the heading that appears in your document.',
      'TEXT4': 'You should remove the rows that appear to you after the table ends from your records.',
      'TEXT5': 'If you did the process correctly in Step 2 it should show you the name of the table headings in your file.',
      'TEXT6': 'If this does not happen, ask for help at support@gameboard.tech with your file attached.',
      'TEXT7': 'If you do not know how to do it press the button "View tutorial"',
      'TEXT8': 'Column 1 No. of Doc: you can identify this column in your file because it contains the transaction numbers, you can find them with other names therefore check in your file.',
      'TEXT9': 'Column 2 of credit (credit): you can identify this column in your file because it contains the credit amounts of your account.',
      'TEXT10': 'Column 3 of debit (debit): you can identify this column in your file because it contains the debit amounts of your account.',
      'TEXT11': 'Column 4 of date: you can identify this column in your file because it contains the dates of your transactions.',
      'TEXT12': 'If you have any questions, ask support@gameboard.tech for help by attaching your file.',
      'TUTORIAL': 'View tutorial',
    },
  }
};
