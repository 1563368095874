// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// Fuse
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class MainComponent implements OnInit {
  complexId = '';
  search = '';

  constructor() { }

  ngOnInit(): void {
  }
  sendSearchFilter(searchString: any): void {
    this.search = searchString;
  }

  sendComplexId(complexId: any): void {
    this.complexId = complexId;
  }

}
