<div class="p-12" [fxHide]="data.length === 0">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 table-employee" fxHide.lt-md>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.EMPLOYEE_NAME'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.name|ReplaceText }} {{ row.surname|ReplaceText}} </td>
    </ng-container>
    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.CREATED_BY'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.createdBy|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="identificationNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.DPI'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.identificationNumber|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="jobEs">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.JOB_ES'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.jobEs|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="jobEn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.JOB_EN'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.jobEn|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="mainPhone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.PHONE'|translate }} </th>
      <td mat-cell *matCellDef="let row">+{{ row.countryCode }} {{ row.mainPhone|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.EMAIL'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.email|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TABLE.ACTIONS'|translate }} </th>
      <td mat-cell *matCellDef="let row">
        <button mat-raised-button color="primary"
                matTooltip="{{ 'MESSAGES.DETAILS'|translate }}"
                [routerLink]="['/employee/main/detail', complexId, row.id]">
          <mat-icon>settings</mat-icon>
        </button>
        <button mat-raised-button color="warn" class="ml-4" (click)='deleteEmployee(row.id)'
                matTooltip="{{ 'MESSAGES.DELETE'|translate }}">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="column" fxFlexFill fxHide.gt-sm>
    <mat-card class="w-100-p mt-8 mb-8" *ngFor="let employee of data">
      <div fxLayout="column" fxLayoutAlign="center space-between">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.EMPLOYEE_NAME'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ employee.name|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.CREATED_BY'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ employee.createdBy|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.DPI'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ employee.identificationNumber|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.JOB_ES'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ employee.jobEs|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.JOB_EN'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ employee.jobEn|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.PHONE'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ employee.mainPhone|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.EMAIL'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ employee.email|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center" class="mt-16">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.ACTIONS'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            <button mat-raised-button color="primary"
                    matTooltip="{{ 'MESSAGES.DETAILS'|translate }}"
                    [routerLink]="['/employee/main/detail', complexId, employee.id]">
              <mat-icon>settings</mat-icon>
            </button>
            <button mat-raised-button color="warn" class="ml-4" (click)='deleteEmployee(employee.id)'
                    matTooltip="{{ 'MESSAGES.DELETE'|translate }}">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <mat-paginator
    [length]="totalItems"
    [pageSize]="itemsPerPage"
    (page)="changePage($event)"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons>
  </mat-paginator>
</div>
<div class="mat-display-2 mt-8" align="center" *ngIf="data.length === 0">
  {{ 'TABLE.EMPTY'|translate }}
</div>


