export const locale = {
  lang: 'es',
  data: {
    'INFO': {
      'DETAIL': 'Detalles del empleado',
      'EMPLOYEE': 'Información',
      'NAME': 'Nombre: ',
      'DPI': 'DPI: ',
      'PHONE': 'Teléfono: ',
      'SALARY_TITLE': 'Detalle de salario ',
      'SALARY': 'Sueldo: ',
      'BOND': 'Bonos: ',
      'DISCOUNT': 'Descuentos: ',
      'JOB': 'Trabajo: ',
      'EMAIL': 'Correo: ',
      'TAX': 'NIT: ',
      'EDIT': 'Editar empleado',
      'BACK': 'Regresar',
      'TOTAL': 'Total devengado: ',
      'TOTAL_DISCOUNT': 'Total de descuentos: ',
      'TOTAL_BOND': 'Total de bonos: ',
      'ID': 'No.',
      'DESCRIPTION': 'Descripción',
      'AMOUNT': 'Monto',
    },
  }
};
