<div class="p-12" [fxHide]="distributionConfig === false || data.length === 0">
  <div class="right-side"
       fxLayout.gt-sm="row"
       fxLayout.lt-md="column"
       fxLayoutAlign.gt-sm="end"
       fxLayoutAlign.lt-md="center">
    <span><mat-icon class="icon-status-green ml-12">error</mat-icon> {{ 'TABLE.ACTIVE'|translate }}</span>
    <span><mat-icon class="icon-status-red ml-12">error</mat-icon> {{ 'TABLE.INACTIVE'|translate }}</span>
  </div>
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 table-distribution" fxHide.lt-md>
    <ng-container matColumnDef="propertyNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.NO_PROPERTY'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.propertyNumber|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="tenant">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.TENANT'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.tenant|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="propertyEnvironmentMt2">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.SPACE'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.propertyEnvironmentMt2|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="propertyAmenityEnvironmentMt2">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.AMENITIES'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.propertyAmenityEnvironmentMt2|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="mt2Total">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.TOTAL'|translate }} </th>
      <td mat-cell *matCellDef="let row"> {{ row.mt2Total|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="fee">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.QUOTA'|translate }} </th>
      <td mat-cell *matCellDef="let row">{{ row.currency.iso }} {{ row.fee|ReplaceText }} </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TABLE.STATUS'|translate }} </th>
      <td mat-cell mat-cell *matCellDef="let row">
        <mat-icon class="icon-status-red" *ngIf="row.tenant !== null">error</mat-icon>
        <mat-icon class="icon-status-green" *ngIf="row.tenant == null">error</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TABLE.ACTIONS'|translate }} </th>
      <td mat-cell *matCellDef="let row">
        <button mat-raised-button
                color="accent"
                class="ml-4"
                matTooltip="{{ 'MESSAGES.EDIT'|translate }}"
                [routerLink]="['/distribution/main/configuration', complexId, row.propertyId]">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="column" fxFlexFill fxHide.gt-sm>
    <mat-card class="w-100-p mt-8 mb-8" *ngFor="let distribution of data">
      <div fxLayout="column" fxLayoutAlign="center space-between">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.NO_PROPERTY'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ distribution.propertyNumber|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.TENANT'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ distribution.tenant|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.SPACE'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ distribution.propertyEnvironmentMt2|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.AMENITIES'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ distribution.propertyAmenityEnvironmentMt2|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.TOTAL'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            {{ distribution.mt2Total|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.QUOTA'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
           {{ distribution.currency.iso }} {{ distribution.fee|ReplaceText }}
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center" class="mt-16">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.STATUS'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            <mat-icon class="icon-status-red" *ngIf="distribution.tenant !== null">error</mat-icon>
            <mat-icon class="icon-status-green" *ngIf="distribution.tenant == null">error</mat-icon>
          </div>
        </div>
        <mat-divider inset></mat-divider>
        <mat-divider inset></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center" class="mt-16">
          <div fxFlex fxLayoutAlign="start">
            <strong>{{ 'TABLE.ACTIONS'|translate }}:</strong>
          </div>
          <div fxFlex fxLayoutAlign="end">
            <button mat-raised-button color="accent" class="ml-4"
                    matTooltip="{{ 'MESSAGES.EDIT'|translate }}"
                    [routerLink]="['/distribution/main/configuration', complexId, distribution.propertyId]">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <mat-paginator
    [length]="totalItems"
    [pageSize]="itemsPerPage"
    (page)="changePage($event)"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons>
  </mat-paginator>
</div>
<div class="p-12" *ngIf="distributionConfig == false">
  <mat-card class="config">
    <div fxLayoutAlign="strech center">
      <span class="h3"><strong>{{ 'MESSAGES.NOT_CONFIGURATION'|translate }}</strong></span>
      <button mat-raised-button color="primary" class="ml-12"
              matTooltip="{{ 'MESSAGES.CLICK'|translate }}"
              (click)="goToUrl();">
        <mat-icon>build</mat-icon>
      </button>
    </div>
  </mat-card>
</div>
